import { SmartphoneOutlined } from '@mui/icons-material'
import { Box, Button, Input } from '@mui/material'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GenericDataType, OTPApiService, UserApiService } from 'services'
import { enforceFormat, isModifierKey, validatePhoneNumber } from 'utils/functions'
import { LoginProps, StepLogin } from '.'

type OTPResponse = {
  expires_in: number
  send_email: boolean
  send_sms: boolean
  v_code?: string
}

const StepSignUp: React.FC<LoginProps> = ({ onChangeStep, isForgot }) => {
  const { t } = useTranslation('login')
  const [state, setState] = React.useState('')
  const dispatch = useLoginModalDispatch()
  const { data, mutate } = OTPApiService.usePost<GenericDataType<OTPResponse>>({
    url: '/send',
  })

  const { data: dataForgot, mutate: mutateForgot } = UserApiService.usePost<GenericDataType<OTPResponse>>({
    url: '/password/forgot',
  })

  const handleChangeValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isModifierKey(event)) return
    const target = event.target as HTMLInputElement
    setState(target.value)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)

    if (validatePhoneNumber(formProps?.phone?.toString())) {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { phone: formProps?.phone?.toString() },
      })
      if (isForgot) {
        mutateForgot({
          data: {
            username: formProps?.phone?.toString(),
          },
        })
        return
      }
      mutate({
        data: formProps,
      })
    }
  }

  React.useEffect(() => {
    if (data) {
      onChangeStep?.(StepLogin.OTP)
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { otp_expire: data?.data?.result[0]?.expires_in, v_code: data?.data?.result[0]?.v_code },
      })
    }

    if (isForgot && dataForgot?.data?.result) {
      onChangeStep?.(StepLogin.SENT_OTP_FORGOT_PASSWORD)
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { otp_expire: dataForgot?.data?.result[0]?.expires_in, v_code: dataForgot?.data?.result[0]?.v_code },
      })
    }
  }, [data, dataForgot, isForgot, dispatch, onChangeStep])

  return (
    <Box component={'form'} className="flex flex-col justify-center items-center p-12 w-full" onSubmit={handleSubmit}>
      <Box className="text-content-16 font-bold text-center mb-6">{t('please_input_phone_number')}</Box>
      <Input
        name="phone"
        startAdornment={<SmartphoneOutlined className="!text-grey-1" />}
        className="mb-4"
        placeholder={t('phone_number') || ''}
        inputProps={{ maxLength: 12 }}
        onKeyUp={handleChangeValue}
        onKeyDown={enforceFormat}
      />
      {!!state && !validatePhoneNumber(state) && <Box className="text-brand-1">{t('wrong_format_phone_number')}</Box>}
      <Button
        type="submit"
        className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mx-auto !mt-4 disabled:!bg-grey-1"
        disabled={state === ''}
      >
        {t('continue')}
      </Button>
    </Box>
  )
}

export default StepSignUp
