import { CircularProgress, Pagination, Tab, Tabs, ThemeProvider, createTheme } from '@mui/material'
import Box from '@mui/material/Box'
import CardItem from 'components/CardItem'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import { useQueryParams } from 'hooks/useQueryParams'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ApiParamsProps, DefaultApiService, GenericDataType } from 'services'
import { ItemType } from 'utils/types'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#DF3E23',
            fontWeight: 'bold',
          },
          color: '#ADB3BC',
          textTransform: 'none',
          fontSize: '16px',
        },
      },
    },
  },
})

const defaultParams = {
  limit: 10,
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

enum TabsFavorite {
  RENT = 'BDS_RENT',
  FOR_SALE = 'BDS_SALE',
  SUPER_CAR = 'SUPER_CAR',
  MOTOR_BIKE = 'MOTOR_BIKE',
}

const FavoritePage = () => {
  const currentParams = useQueryParams()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { t } = useTranslation('profile')
  const [tab, setTabs] = React.useState<TabsFavorite>(TabsFavorite.RENT)

  const { data, isLoading, refetch } = DefaultApiService.useGet<GenericDataType<ItemType>>({
    url: '/favorites',
    params: { ...currentParams, ...defaultParams } as ApiParamsProps,
  })

  const listTabs = React.useMemo(
    () => [
      {
        label: t('bds_for_rent'),
        value: TabsFavorite.RENT,
      },
      {
        label: t('bds_for_sale'),
        value: TabsFavorite.FOR_SALE,
      },
      {
        label: t('car_for_rent'),
        value: TabsFavorite.SUPER_CAR,
      },
      {
        label: t('motorbike_for_rent'),
        value: TabsFavorite.MOTOR_BIKE,
      },
    ],
    [t],
  )

  const mappingData: ItemType[] = React.useMemo(() => {
    if (data?.data?.result) {
      return data?.data?.result
    }
    return []
  }, [data?.data?.result])

  const handleChangeTab = (e: React.SyntheticEvent, value: TabsFavorite) => {
    setTabs(value)

    if (value === TabsFavorite.FOR_SALE) {
      const newParams = { ...currentParams, commodity_type: 'HOUSE', type_rental: 'FOREVER', type: undefined, page: 1 }
      history.push(path + '?' + qs.stringify(newParams))
    }
    if (value === TabsFavorite.RENT) {
      const newParams = { ...currentParams, commodity_type: 'HOUSE', type_rental: undefined, page: 1 }
      history.push(path + '?' + qs.stringify(newParams))
    }
    if (value === TabsFavorite.MOTOR_BIKE) {
      const newParams = {
        ...currentParams,
        commodity_type: 'VEHICLE',
        type: 'MOTOR_BIKE',
        page: 1,
        type_rental: undefined,
      }
      history.push(path + '?' + qs.stringify(newParams))
    }
    if (value === TabsFavorite.SUPER_CAR) {
      const newParams = {
        ...currentParams,
        commodity_type: 'VEHICLE',
        type: 'SUPER_CAR',
        page: 1,
        type_rental: undefined,
      }
      history.push(path + '?' + qs.stringify(newParams))
    }
  }

  const handleChange = (_: any, page: number) => {
    const newParams = { ...currentParams, page }
    history.push(path + '?' + qs.stringify(newParams))
  }

  React.useEffect(() => {
    if (currentParams) {
      if (currentParams?.commodity_type === 'HOUSE') {
        if (currentParams?.type_rental === 'FOREVER') {
          setTabs(TabsFavorite.FOR_SALE)
        } else setTabs(TabsFavorite.RENT)
      } else {
        if (currentParams?.type === 'MOTOR_BIKE') {
          setTabs(TabsFavorite.MOTOR_BIKE)
        } else setTabs(TabsFavorite.SUPER_CAR)
      }
    }
  }, [currentParams])

  return (
    <ThemeProvider theme={theme}>
      <CustomBreadcrumbs />
      <Box className="pb-[136px] max-w-[1440px] mx-auto px-14 pt-6">
        <Box className="flex justify-center mb-6">
          <Tabs
            className="!min-h-0 !mx-auto"
            value={tab}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#DF3E23',
              },
            }}
            variant="scrollable"
            scrollButtons
          >
            {listTabs.map((item, index) => (
              <Tab
                className="!min-h-0 !px-0 tablet:!mx-5 !text-content-16"
                label={item.label}
                {...a11yProps(index)}
                key={index}
                iconPosition="start"
                value={item.value}
              />
            ))}
          </Tabs>
        </Box>
        {isLoading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="flex flex-wrap gap-5">
            {mappingData?.map((item, index) => (
              <CardItem
                id={item._id}
                key={index}
                image={item?.images?.[0]?.image || ''}
                type={item.type}
                name={item.name}
                bathCount={item.n_bathroom}
                bedCount={item.n_bedroom}
                price={item.price_share || item.price}
                type_rental={item.type_rental}
                updatedTime={item.updated_at}
                isLiked
                onRefetchAfterLike={() => refetch()}
              />
            ))}
          </Box>
        )}
        {!isLoading && !mappingData?.length && (
          <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>
        )}
        {!!mappingData?.length && (
          <Box className="flex justify-center mt-8">
            <Pagination
              variant="outlined"
              count={data?.data?.totalPage}
              shape="rounded"
              onChange={handleChange}
              disabled={isLoading}
              page={Number(currentParams?.page) || 1}
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  )
}

export default FavoritePage
