import { Box } from '@mui/material'
import { OptionsAddress } from 'components/Filter'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DefaultApiService, GenericDataType } from 'services'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import ROUTES from 'utils/constants/routesConstant'

type ButtonPlaceProps = {
  srcImage?: string
  name?: string
  onClick?: () => void
}

const ButtonPlace: React.FC<ButtonPlaceProps> = ({ srcImage, name, onClick }) => {
  return (
    <Box
      className="relative !w-[144px] !h-[144px] !p-0 !normal-case cursor-pointer"
      onClick={onClick}
      draggable="false"
    >
      <Box
        component={'img'}
        src={srcImage}
        alt=""
        className="w-[144px] h-[144px] rounded-full object-cover pointer-events-none"
      />
      <Box className="text-content-14 absolute bottom-0 bg-white w-full text-center border-t-2 text-black font-semibold border-black-1">
        {name}
      </Box>
    </Box>
  )
}

const DiscoveryNearby = () => {
  const { t } = useTranslation('home')
  const { data: dataDistrict } = DefaultApiService.useGet<GenericDataType<OptionsAddress>>({
    url: '/cadastral/district/31',
  })
  const history = useHistory()

  const handleRoute = (code?: string) => {
    if (code) {
      history.push(`${ROUTES.FOR_RENT}?page=1&district=${code}`)
    }
  }

  return (
    <Box className="mt-4">
      <TitleWithRoute
        buttonText={t('go_discover_now')}
        href="/"
        title={t('discovery_title')}
        isNoButton
        type={TitleWithRouteType.BACKGROUND_BUTTON}
      />
      {/* <Box className="flex flex-wrap mb-6 gap-6"> */}
      <Swiper
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={2}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>'
          },
        }}
        breakpoints={{
          550: {
            slidesPerView: 2.5,
          },
          650: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3.5,
          },
          980: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 6,
          },
          1550: { slidesPerView: 7 },
        }}
      >
        {dataDistrict?.data?.result.map((item, index) => (
          <SwiperSlide className="!h-[200px]" key={index}>
            <ButtonPlace name={item.name} srcImage={item.image} onClick={() => handleRoute(item?.code)} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* </Box> */}
    </Box>
  )
}

export default DiscoveryNearby
