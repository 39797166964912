import { FavoriteBorderOutlined, FavoriteOutlined, ShareOutlined, WarningAmberRounded } from '@mui/icons-material'
import { Box, IconButton, Popper } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GenericDataType, HouseApiService, VehicleApiService } from 'services'
import { ProductType } from 'utils/functions'
import { ItemType } from 'utils/types'
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share'
import { FacebookIcon, TelegramIcon, TwitterIcon } from 'react-share'
import { useHistory, useLocation } from 'react-router-dom'
import { domain } from 'utils/constants'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'

const Social = ({ data, refetch }: { data?: ItemType; refetch?: () => void }) => {
  const { t } = useTranslation('detail')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { token } = useAuth()
  const dispatch = useLoginModalDispatch()
  const { mutate: mutateHouse, isSuccess: isSuccessHouse } = HouseApiService.usePatch<GenericDataType<ItemType>>({
    url: `/${data?._id}/favorite`,
  })

  const { mutate: mutateVehicle, isSuccess: isSuccessVehicle } = VehicleApiService.usePatch<GenericDataType<ItemType>>({
    url: `/${data?._id}/favorite`,
  })

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    },
    [anchorEl],
  )

  const open = Boolean(anchorEl)

  const id = open ? 'popper' : undefined

  const handleClickLike = React.useCallback(() => {
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }
    return ProductType(data?.type) === 'HOUSE' ? mutateHouse({ data: {} }) : mutateVehicle({ data: {} })
  }, [data?.type, dispatch, mutateHouse, mutateVehicle, token])

  React.useEffect(() => {
    if (isSuccessHouse || isSuccessVehicle) {
      refetch?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessHouse, isSuccessVehicle])

  const listButton = React.useMemo(
    () => [
      {
        icon: data?.is_favorite ? <FavoriteOutlined className={'text-brand-1'} /> : <FavoriteBorderOutlined />,
        onClick: handleClickLike,
        id: '',
      },
      { icon: <ShareOutlined />, value: '', id: 'popper', onClick: handleClick },
      { icon: <WarningAmberRounded />, value: '', id: '' },
    ],
    [data?.is_favorite, handleClick, handleClickLike],
  )

  const listSocialButton = React.useMemo(() => {
    return [
      {
        item: (
          <FacebookShareButton url={window.location.href}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        ),
      },
      {
        item: (
          <TelegramShareButton url={window.location.href}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
        ),
      },
      {
        item: (
          <TwitterShareButton url={window.location.href}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        ),
      },
    ]
  }, [])

  return (
    <Box>
      <Box className="text-content-14 font-bold text-brand-1">{t('share_the_post')}</Box>
      <Box>
        {listButton.map((item, index) => (
          <IconButton
            key={index}
            className="!text-access-green"
            onClick={item.onClick && item.onClick}
            id={item.id}
            aria-describedby={item.id}
          >
            {item.icon}
          </IconButton>
        ))}
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Box className="flex gap-1 drop-shadow-xl p-2 rounded-lg bg-white">
            {listSocialButton.map((item, index) => (
              <Box key={index} className="!h-[32px]">
                {item.item}
              </Box>
            ))}
          </Box>
        </Popper>
      </Box>
    </Box>
  )
}

export default Social
