import Box from '@mui/material/Box'
import CardItem from 'components/CardItem'
import useAuth from 'hooks/useAuth'
import usePath from 'hooks/usePath'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { GenericDataType, HouseApiService, VehicleApiService } from 'services'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CACHE_DATA } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { ProductType, generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

const ViewProducts = () => {
  const { t } = useTranslation('detail')
  const history = useHistory()
  const { path } = usePath()
  const [data, setData] = React.useState<ItemType[]>([])
  const { token } = useAuth()

  const { data: dataHouse, refetch } = HouseApiService.useGet<GenericDataType<ItemType>>({
    url: '/revert-seen',
    params: {
      limit: 2,
      page: 1,
    },
    options: {
      enabled:
        !!token &&
        (path.includes(ROUTES.HOUSE) ||
          path === ROUTES.FOR_RENT ||
          path.includes(ROUTES.NEED_SELL) ||
          path.includes(ROUTES.PROJECT)),
    },
  })

  const { data: dataVehicle, refetch: refetchVehicle } = VehicleApiService.useGet<GenericDataType<ItemType>>({
    url: '/revert-seen',
    params: {
      limit: 3,
      page: 1,
    },
    options: {
      enabled: !!token && path.includes(ROUTES.VEHICLE),
    },
  })

  React.useEffect(() => {
    const localData = localStorage.getItem(CACHE_DATA)
    if (localData) {
      const parsedData: ItemType[] = JSON.parse(localData)
      if (!token) {
        if (parsedData && parsedData.length) {
          if (path.includes(ROUTES.VEHICLE)) {
            const filteredData = parsedData.filter(x => ProductType(x.type) === 'HOUSE')
            setData(filteredData.slice(0, 10).reverse())
          } else {
            const filteredData = parsedData.filter(x => ProductType(x.type) === 'HOUSE')
            setData(filteredData.slice(0, 10).reverse())
          }
        } else setData([])
      } else {
        if (path.includes(ROUTES.VEHICLE)) {
          if (dataVehicle?.data?.result?.length) {
            setData(dataVehicle?.data?.result)
          } else {
            const filteredData = parsedData.filter(x => ProductType(x.type) === 'VEHICLE')
            setData(filteredData.slice(0, 10).reverse())
          }
        } else {
          if (dataHouse?.data?.result?.length) {
            setData(dataHouse?.data?.result)
          } else {
            const filteredData = parsedData.filter(x => ProductType(x.type) === 'HOUSE')
            setData(filteredData.slice(0, 10).reverse())
          }
        }
      }
    }
  }, [dataHouse?.data?.result, dataVehicle?.data?.result, path, token])

  if (!data?.length) return null

  return (
    <Box className="bg-white rounded-xl p-8">
      <Box className="text-content-18 font-bold text-brand-1 mb-2">{t('watched_products')}</Box>
      <Box className="mt-8">
        <Swiper
          modules={[Pagination]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>'
            },
          }}
          breakpoints={{
            350: {
              slidesPerView: 1.2,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2.2,
            },
            980: {
              slidesPerView: 3.5,
            },
          }}
        >
          {data?.map((item, index) => (
            <SwiperSlide className="!h-[500px]" key={index}>
              <Box className="p-2">
                <CardItem
                  id={item._id}
                  key={index}
                  type={item.type}
                  name={item.name}
                  image={item?.images?.[0]?.image || ''}
                  bathCount={item.n_bathroom}
                  price={item.sale_price || item.price}
                  type_rental={item.type_rental}
                  bedCount={item.n_bedroom}
                  updatedTime={item.updated_at}
                  isLiked={item?.is_favorite}
                  onRefetchAfterLike={() => (ProductType(item.type) === 'HOUSE' ? refetch() : refetchVehicle())}
                  href={generateHref({ item: item })}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default ViewProducts
