/* eslint-disable prettier/prettier */
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import useAuth from 'hooks/useAuth'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { UserApiService } from 'services'
import { useSnackbar } from 'notistack'

const ChangePassword = () => {
  const { t } = useTranslation('profile')
  const { token } = useAuth()
  const dispatch = useLoginModalDispatch()
  const [showPassword, setShowPassword] = React.useState<{ [key: string]: boolean }>({
    old_password: false,
    new_password: false,
    re_entry_password: false,
  })
  const loginContext = useLoginModalContext()
  const { enqueueSnackbar } = useSnackbar()

  const { isSuccess, mutate } = UserApiService.usePatch({ url: `/${loginContext?._id}/password` })

  const listInput = React.useMemo(
    () => [
      { label: t('current_password'), name: 'old_password' },
      { label: t('new_password'), name: 'new_password' },
      { label: t('re_entry_password'), name: 're_entry_password' },
    ],
    [t],
  )

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    if (formProps?.new_password !== formProps?.re_entry_password) {
      enqueueSnackbar(t('not_same_password'), { variant: 'error' })
      return
    }
    if (loginContext?._id) {
      mutate({
        data: {
          old_password: formProps?.old_password,
          new_password: formProps?.new_password,
        },
      })
    }
  }

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t('save_success'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isSuccess, t])

  return (
    <Box className="w-full bg-white rounded-xl flex justify-center items-center min-h-[328px]">
      <Box className="max-w-[586px] w-full p-6" component={'form'} onSubmit={handleSubmit}>
        <Box className="flex items-center justify-between">
          <Box className="!w-[125px]"> </Box>
          <Box className="text-content-18 font-bold max-w-[380px] w-full">{t('change_password')}</Box>
        </Box>
        {listInput.map((item, index) => (
          <Box key={index} className="flex items-center justify-between my-5">
            <InputLabel className="!text-content-14 !w-[125px] text-black-1">{item.label}</InputLabel>
            <OutlinedInput
              size="small"
              className="max-w-[380px] w-full"
              sx={{
                '& .MuiInputBase-input': {
                  padding: '6px 12px',
                  fontSize: '14px',
                },
              }}
              placeholder={item.label}
              name={item.name}
              type={showPassword[item.name] ? 'text' : 'password'}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(prev => ({ ...prev, [item.name]: !prev[item.name] }))}
                    edge="end"
                  >
                    {showPassword[item.name] ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        ))}
        <Box className="flex items-center justify-between mt-6">
          <Box className="!w-[125px]"> </Box>
          <Box className="text-content-18 font-bold max-w-[380px] w-full">
            <Button type="submit" className="!bg-brand-1 !normal-case !text-white !w-[144px] !text-content-12">
              {t('save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ChangePassword
