import { Box, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Properties } from './HouseDetail/ListProperties'
import listIcon from 'utils/constants/iconConstant'

type Props = {
  data: Properties
  description?: string
}

const VehicleDetail: React.FC<Props> = ({ data, description }) => {
  const { t } = useTranslation('detail')

  const mappingData = React.useMemo(() => {
    if (data) {
      const keys = Object.keys(data)
      return keys.map(item => ({
        icon: listIcon[item],
        label: t(item),
        value: data[item],
      }))
    }
  }, [data, t])

  return (
    <Box className="w-full">
      <Box className="text-content-18 font-bold text-brand-1 mb-3">{t('bds_detail')}</Box>
      <Box className="flex flex-wrap justify-between gap-4">
        {mappingData?.map((item, index) => (
          <Box
            key={index}
            className="flex max-w-[45%] min-w-[300px] w-full text-content-14 border-b-2 pb-2 items-center"
          >
            <Box className="flex min-w-[200px] text-grey-1 items-center gap-1">
              <Box>{item.icon}</Box>
              <Box>{item.label}</Box>
            </Box>
            <Box className="font-bold">{item.value}</Box>
          </Box>
        ))}
      </Box>
      <Box className="text-content-18 text-brand-1 font-bold mt-3">{t('description')}</Box>
      <Box className="text-content-14 text-[#333333] mt-2">{description}</Box>
      <Divider className="!my-2" />
    </Box>
  )
}

export default VehicleDetail
