import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavItem } from './AboutUs'
import { CGV } from '..'
import ROUTES from 'utils/constants/routesConstant'

const HelpCenter = ({ data }: { data?: CGV[] }) => {
  const { t } = useTranslation('footer')

  const listNav = [
    { text: t('faq'), href: '/trang/cac-cau-hoi-thuong-gap' },
    { text: t('tutorial'), href: '/trang/huong-dan-dat-hang' },
    { text: t('payment'), href: '/trang/phuong-thuc-thanh-toan' },
    { text: t('shipping'), href: '/trang/phuong-thuc-van-chuyen' },
    { text: t('return_policy'), href: '/trang/chinh-sach-doi-tra' },
    { text: t('cskh'), href: '/trang/cac-cau-hoi-thuong-gap' },
    { text: t('new_blog'), href: `${ROUTES.NEWS}?page=1` },
  ]

  return (
    <Box>
      <Box className="text-content-16 text-black-1 font-semibold">{t('help_center')}</Box>
      <Box className="text-content-12 mt-4 mb-3">
        <Box component={'div'}>
          {t('hotline')}
          <Box component={'span'} className=" text-black-1 font-bold">
            {t('hotline_phone')}
          </Box>
        </Box>
        <Box>{t('money_per_min')}</Box>
      </Box>
      <Box>
        {listNav?.map((item, index) => (
          <NavItem href={item.href} text={item.text} key={index} />
        ))}
      </Box>
    </Box>
  )
}

export default HelpCenter
