import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import qs from 'query-string'

export function useQueryParams(val?: object) {
  const { search } = useLocation()
  return useMemo(() => {
    const value = val || {}
    return { ...value, ...qs.parse(search) } as { [key: string]: string }
  }, [search, val])
}
