import { DoneRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoginProps } from '.'

const StepDone: React.FC<LoginProps> = ({ onClose, title, description, buttonText }) => {
  const { t } = useTranslation('login')
  return (
    <Box className={'flex flex-col justify-center items-center w-full p-10'}>
      <Box
        className={'p-4 rounded-full'}
        sx={{
          backgroundColor: '#DF3E2308',
        }}
      >
        <DoneRounded
          className={'text-brand-1 !text-[90px] rounded-full p-2'}
          sx={{
            backgroundColor: '#DF3E2310',
          }}
        />
      </Box>
      <Box className="font-bold text-brand-1 text-content-title text-center mt-6 tablet:w-full w-[200px]">{title}</Box>
      <Box className="text-content-18 text-grey-1 text-center mt-3">{t('welcome')}</Box>
      <Button
        className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mx-auto !mt-3"
        onClick={() => onClose?.()}
      >
        {t('done')}
      </Button>
    </Box>
  )
}

export default StepDone
