import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConfirmImage from 'assets/images/confirm-image.png'
import { Link } from 'react-router-dom'

export const NavItem = ({ href, text }: { href: string; text?: string }) => {
  return (
    <Box component={'div'} className="text-content-12 mb-3 hover:text-white">
      <Link to={href}>{text}</Link>
    </Box>
  )
}

const AboutUs = () => {
  const { t } = useTranslation('footer')
  const listNav = [
    { text: t('introduce_cyber'), href: '/trang/gioi-thieu-cyberlife' },
    { text: t('complaint_handling_policy'), href: '/trang/chinh-sach-giai-quyet-khieu-nai' },
    { text: t('payment_privacy_policy'), href: '/trang/chinh-sach-bao-mat-thanh-toan' },
    { text: t('privacy_policy'), href: '/trang/chinh-sach-bao-mat' },
    { text: t('recruitment'), href: '/trang/tuyen-dung' },
    { text: t('contact'), href: '/trang/lien-he' },
  ]

  return (
    <Box>
      <Box className="text-content-16 text-black-1 font-semibold">{t('about_us')}</Box>
      <Box className="mt-4">
        {listNav.map((item, index) => (
          <NavItem href={item.href} text={item.text} key={index} />
        ))}
      </Box>
      <img src={ConfirmImage} alt="" />
    </Box>
  )
}

export default AboutUs
