import { Search } from '@mui/icons-material'
import { Autocomplete, Box, Button, TextField } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'
import { ListAutocomplete } from '.'

type CurrentParams = {
  [key: string]: string | undefined
}

const FilterForRent = ({
  route,
  listAutocomplete,
  isLoading,
  currentParams,
}: {
  currentParams: CurrentParams
  route?: string
  listAutocomplete?: ListAutocomplete[]
  isLoading?: boolean
}) => {
  const { t } = useTranslation('home')
  const history = useHistory()
  const [state, setState] = React.useState<CurrentParams>()

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    let newState = {}
    switch (route) {
      case ROUTES.FOR_RENT: {
        newState = {
          q: state?.q,
          district: state?.district,
          type: state?.type === 'SUPER_CAR' || state?.type === 'MOTOR_BIKE' ? undefined : state?.type,
          type_rental: state?.type_rental === 'FOREVER' ? 'DAY' : state?.type_rental,
        }

        history.push(`${route}?${qs.stringify(newState)}`)
        break
      }
      case ROUTES.NEED_SELL: {
        newState = {
          q: state?.q,
          district: state?.district,
          type_rental: 'FOREVER',
        }

        history.push(`${route}?${qs.stringify(newState)}`)
        break
      }
      default:
        break
    }
  }

  React.useEffect(() => {
    setState(currentParams)
  }, [currentParams])

  return (
    <Box component={'form'} onSubmit={handleSubmit} className="flex flex-wrap gap-3 items-end">
      <Box>
        <Box className="text-black-1 text-content-14 font-bold mb-1">{t('quick_search')}</Box>
        <TextField
          placeholder={t('search_placeholder') || ''}
          name="q"
          defaultValue={currentParams?.q || ''}
          onChange={e => setState(prev => ({ ...prev, q: e.target.value }))}
          InputProps={{
            endAdornment: <Search className="text-icon" />,
            style: { borderRadius: '12px' },
            sx: {
              input: {
                padding: '12.5px 14px',
                fontSize: '14px',
              },
              'input::placeholder': { fontStyle: 'italic' },
            },
          }}
        />
      </Box>
      {listAutocomplete?.map((item, index) => {
        return (
          <Box key={index}>
            <Box className="text-black-1 text-content-14 font-bold mb-1">{item.label}</Box>
            <Autocomplete
              className="w-[188px]"
              options={item.options}
              defaultValue={item.options.find(x => x.value === item?.defaultValue)}
              onChange={(e, v) => setState(prev => ({ ...prev, [item.name]: v?.value }))}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '5px 14px',
                  borderRadius: '12px',
                },
                'input::placeholder': { fontStyle: 'italic', fontSize: '14px' },
              }}
              renderInput={params => <TextField {...params} placeholder={item.placeholder} name={item.name} />}
            />
          </Box>
        )
      })}
      <Button type="submit" className="h-12 !bg-brand-1 w-[90px] !rounded-xl">
        <Search className="text-white" />
      </Button>
    </Box>
  )
}

export default FilterForRent
