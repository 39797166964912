import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import React from 'react'
import AddressItem from './AddressItem'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import ModalAddress, { ModalAddressType } from './ModalAddress'
import { AuthApiService, GenericDataType, UserApiService } from 'services'
import { AddressProfile, ProfileResponse } from 'layouts/components/LoginModal/StepSignIn'
import useAuth from 'hooks/useAuth'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import _ from 'lodash'
import { useSnackbar } from 'notistack'

const Address = () => {
  const { t } = useTranslation('profile')
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [selectedId, setSelectedId] = React.useState<string | number | undefined>('')
  const [modalType, setModalType] = React.useState<ModalAddressType>(ModalAddressType.ADD)
  const { token } = useAuth()
  const dispatch = useLoginModalDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { data, refetch } = AuthApiService.useGet<GenericDataType<ProfileResponse>>({
    url: '/me',
    options: { enabled: !!token },
  })

  const { mutate, isSuccess } = UserApiService.usePatch<GenericDataType<ProfileResponse>>({
    url: `/${data?.data?.result[0]?._id}/profile`,
  })

  const handleSubmit = (type: ModalAddressType, value: { [k: string]: any }) => {
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }

    if (type === ModalAddressType.ADD) {
      handleSubmitAdd(value)
    }

    if (type === ModalAddressType.DELETE) {
      handleDelete()
    }

    if (type === ModalAddressType.EDIT) {
      handleSubmitEdit(value)
    }
    setIsOpen(false)
  }

  const handleSubmitAdd = (value: { [k: string]: any }) => {
    const sortedArray = data?.data?.result[0]?.address?.sort((a, b) => Number(a.id) - Number(b.id))
    const maxId = Number(sortedArray?.[0]?.id || 0) + 1
    const newObj: AddressProfile = { ...value, id: maxId } as AddressProfile
    const newArray: AddressProfile[] = _.cloneDeep(data?.data?.result[0]?.address) || []
    if (newObj?.is_default) {
      for (const i of newArray) {
        i.is_default = false
      }
    }
    newArray.push({ ...newObj })
    mutate({
      data: {
        address: newArray,
      },
    })
  }

  const handleDelete = () => {
    const oldArray: AddressProfile[] = _.cloneDeep(data?.data?.result[0]?.address) || []
    const newArray: AddressProfile[] = oldArray.filter(x => x.id !== selectedId)
    mutate({
      data: {
        address: newArray,
      },
    })
  }

  const handleSubmitEdit = (value: { [k: string]: any }) => {
    const newObj: AddressProfile = { ...value, id: selectedId } as AddressProfile
    const oldArray: AddressProfile[] = _.cloneDeep(data?.data?.result[0]?.address) || []
    const index = oldArray.findIndex(x => x.id === selectedId)
    oldArray[index] = newObj as AddressProfile
    mutate({
      data: {
        address: oldArray,
      },
    })
  }

  const handleSetDefault = (id?: string | number) => {
    const oldArray: AddressProfile[] = _.cloneDeep(data?.data?.result[0]?.address) || []
    const index = oldArray.findIndex(x => x.id === id)
    for (const i of oldArray) {
      i.is_default = false
    }
    oldArray[index] = { ...oldArray[index], is_default: true } as AddressProfile
    mutate({
      data: {
        address: oldArray,
      },
    })
  }

  const handleOpenModal = (id?: string | number) => {
    setSelectedId(id)
    setIsOpen(true)
  }

  React.useEffect(() => {
    if (isSuccess) {
      refetch()
      enqueueSnackbar(t('update_success'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isSuccess, refetch, t])

  return (
    <Box className="rounded-xl bg-white px-8 py-6">
      <Box className="flex items-center flex-wrap justify-between mb-3">
        <Box className="text-content-18 font-bold text-black-1">{t('address_detail')}</Box>
        <Button
          variant="outlined"
          className="!text-brand-1 !normal-case !border-brand-1 !font-bold"
          startIcon={<Add />}
          onClick={() => {
            setIsOpen(true)
            setModalType(ModalAddressType.ADD)
          }}
        >
          {t('create_address')}
        </Button>
      </Box>
      {data?.data?.result[0]?.address?.map((item, index) => (
        <AddressItem
          onChangeType={setModalType}
          key={index}
          onOpen={id => handleOpenModal(id)}
          data={item}
          setDefault={handleSetDefault}
        />
      ))}
      <ModalAddress
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        type={modalType}
        onSubmit={handleSubmit}
        data={data?.data?.result[0]?.address?.find(x => x.id === selectedId)}
      />
    </Box>
  )
}

export default Address
