import {
  AirOutlined,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  BadgeOutlined,
  BathtubOutlined,
  CalendarMonthOutlined,
  ChairOutlined,
  CountertopsOutlined,
  DeckOutlined,
  FitnessCenterOutlined,
  GrassOutlined,
  GroupOutlined,
  LocalGasStation,
  LocalHotelOutlined,
  LocalParkingOutlined,
  MovingOutlined,
  Policy,
  PoolOutlined,
  PushPinOutlined,
  QueryStatsOutlined,
  SmokeFreeOutlined,
  Title,
  WcOutlined,
} from '@mui/icons-material'

type ListIcon = {
  [key: string]: any
}

const listIcon: ListIcon = {
  livingroom: <ChairOutlined />,
  capacity: <GroupOutlined />,
  bedroom: <LocalHotelOutlined />,
  bathroom: <BathtubOutlined />,
  air_conditional: <AirOutlined />,
  no_smoking: <SmokeFreeOutlined />,
  pool: <PoolOutlined />,
  garage: <LocalParkingOutlined />,
  kitchen: <CountertopsOutlined />,
  outside_kitchen: <DeckOutlined />,
  garden: <GrassOutlined />,
  n_bathroom: <BathtubOutlined />,
  n_bedroom: <LocalHotelOutlined />,
  n_livingroom: <ChairOutlined />,
  n_toilet: <WcOutlined />,
  type_of_vehicle_1: <Title />,
  vehicle_line: <AssignmentOutlined />,
  n_km_traveled: <MovingOutlined />,
  origin: <PushPinOutlined />,
  tonnage: <FitnessCenterOutlined />,
  firms: <BadgeOutlined />,
  n_year_manufacture: <CalendarMonthOutlined />,
  status: <QueryStatsOutlined />,
  fuel: <LocalGasStation />,
  warranty_policy: <Policy />,
  license_plate: <AssignmentTurnedInOutlined />,
}

export default listIcon
