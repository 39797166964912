import { DirectionsCarOutlined, TwoWheelerOutlined } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'

const FilterForVehicle = () => {
  const { t } = useTranslation('home')
  return (
    <Box className="flex gap-5 mt-6">
      <Link to={`${ROUTES.VEHICLE}?page=1&type=SUPER_CAR`}>
        <Button
          className="h-12 !bg-brand-1 !normal-case !font-bold !text-content-12 !text-white !rounded-xl !px-6"
          startIcon={<DirectionsCarOutlined />}
        >
          {t('rent_a_car')}
        </Button>
      </Link>
      <Link to={`${ROUTES.VEHICLE}?page=1&type=MOTOR_BIKE`}>
        <Button
          className="h-12 !bg-brand-1 !normal-case !font-bold !text-content-12 !text-white !rounded-xl !px-6"
          startIcon={<TwoWheelerOutlined />}
        >
          {t('rent_a_motobike')}
        </Button>
      </Link>
    </Box>
  )
}

export default FilterForVehicle
