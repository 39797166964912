import {
  CardGiftcardOutlined,
  KeyboardArrowDown,
  LanguageOutlined,
  LocalShippingOutlined,
  SettingsOutlined,
  WidgetsOutlined,
} from '@mui/icons-material'
import { Box, MenuItem, Select } from '@mui/material'
import { LangPropsEnum } from 'i18n'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import localStorageService from 'services/localStorageService'
import ROUTES from 'utils/constants/routesConstant'

const TopWidget = () => {
  const { t, i18n } = useTranslation('navbar')

  const handleChangeLanguage = (lang: LangPropsEnum) => {
    i18n.changeLanguage(lang)
  }

  const listButtonTopNav = [
    {
      icon: <i className="icon-12 icon-home" />,
      text: t('home'),
      href: '/',
    },
    {
      icon: <i className="icon-12 icon-hop-tac" />,
      text: t('colaboration'),
      href: `/trang/hop-tac-cung-chung-toi`,
    },
    {
      icon: <i className="icon-12 icon-wiki" />,
      text: t('wiki_bds'),
      href: `/trang/wiki-bds`,
    },
    {
      icon: <i className="icon-12 icon-mail" />,
      text: t('send_request'),
      href: '/',
    },
  ]

  return (
    <Box className="h-[40px] bg-brand-1 flex items-center justify-around">
      <Box className="flex gap-10">
        {listButtonTopNav.map((item, index) => (
          <Link key={index} to={item.href} className="text-content-12 text-white flex items-center gap-1">
            {item.icon}
            <Box>{item.text}</Box>
          </Link>
        ))}
      </Box>
      <div></div>
      <Box className="flex items-center gap-1">
        <LanguageOutlined className="!text-content-12 text-white" />
        <Select
          variant="standard"
          disableUnderline
          IconComponent={KeyboardArrowDown}
          sx={{
            svg: { color: 'white' },
          }}
          value={localStorageService.getLocalStorage('i18nextLng')}
          onChange={event => handleChangeLanguage(event.target.value as LangPropsEnum)}
          className="!text-white !text-content-12 !leading-[unset]"
        >
          <MenuItem className="!text-content-12" value={LangPropsEnum.VIE}>
            Tiếng Việt
          </MenuItem>
          {/* <MenuItem className="!text-content-12" value={LangPropsEnum.ENG}>
            English
          </MenuItem>
          <MenuItem className="!text-content-12" value={LangPropsEnum.CN}>
            Chinese
          </MenuItem> */}
        </Select>
      </Box>
    </Box>
  )
}

export default TopWidget
