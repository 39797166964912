import { Box, CircularProgress } from '@mui/material'
import CardItem from 'components/CardItem'
import usePath from 'hooks/usePath'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, GenericDataType, HouseHotApiService, VehicleSearchApiService } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { ProductType, generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

type Props = {
  typeRental?: string
  type?: string
}

const SimilarProducts: React.FC<Props> = ({ typeRental, type }) => {
  const { path } = usePath()
  const history = useHistory()
  const { t } = useTranslation('detail')
  const { data, isLoading, refetch } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type_rental: typeRental } as ApiParamsProps,
    options: {
      enabled:
        path.includes(ROUTES.HOUSE) ||
        path === ROUTES.FOR_RENT ||
        path.includes(ROUTES.NEED_SELL) ||
        path.includes(ROUTES.PROJECT),
    },
  })

  const {
    data: dataVehicle,
    isLoading: isLoadingVehicle,
    refetch: refetchVehicle,
  } = VehicleSearchApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type: type } as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.VEHICLE),
    },
  })

  const filteredData = React.useMemo(() => {
    if (path.includes(ROUTES.VEHICLE)) return dataVehicle?.data?.result
    return data?.data?.result
  }, [data?.data?.result, dataVehicle?.data?.result, path])

  return (
    <Box className="bg-white rounded-xl p-8 desktop:max-w-[310px]">
      <Box className="text-content-18 font-bold text-brand-1 text-center mb-2">{t('similar_product')}</Box>
      {isLoading || isLoadingVehicle ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex desktop:flex-col gap-2 overflow-auto">
          {filteredData?.map((item, index) => (
            <CardItem
              id={item._id}
              key={index}
              type={item.type}
              image={item?.images?.[0]?.image || ''}
              name={item.name}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              price={item?.sale_price || item?.price}
              type_rental={item.type_rental}
              isLiked={item?.is_favorite}
              updatedTime={item.updated_at}
              href={generateHref({ item: item })}
              onRefetchAfterLike={() => (ProductType(item.type) === 'HOUSE' ? refetch() : refetchVehicle())}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default SimilarProducts
