import { Box, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import listIcon from 'utils/constants/iconConstant'
import { Properties } from './ListProperties'

type Props = {
  [key: string]: any
}

const RoomCounter: React.FC<Props> = ({ roomCounter }) => {
  const { t } = useTranslation('detail')
  const listRoomDetail = React.useMemo(() => {
    if (roomCounter) {
      const keys = Object.keys(roomCounter)
      const array: Properties[] = keys.map(k => ({
        value: roomCounter?.[k],
        label: t(k),
        icon: listIcon[k],
      }))
      return array
    }
  }, [roomCounter, t])

  const mappingProp = (num: number, item: Properties) => {
    const array: number[] = []
    for (let i = 0; i < num; i++) {
      array.push(i)
    }
    return array.map((x, index) => (
      <Box key={index} className="w-[100px] h-[100px] rounded-lg p-2" sx={{ border: '1px solid #E8E8E8' }}>
        <Box className="flex gap-1 items-center">
          <Box className="!text-icon !text-content-title">{item.icon}</Box>
        </Box>
        <Box className="text-content-12">
          {item.label} {x + 1}
        </Box>
      </Box>
    ))
  }

  return (
    <Box>
      <Box className="text-content-12 text-brand-1 font-bold">{t('room_counter')}</Box>
      <Box className="flex flex-wrap gap-4 mt-2">
        {listRoomDetail?.map((item, index) => {
          return mappingProp(index, item)
        })}
      </Box>
      <Divider className="!my-2" />
    </Box>
  )
}

export default RoomCounter
