import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import newsBgBotRight from 'assets/images/bgbotright.png'
import newsBgTopRight from 'assets/images/bgtopright.png'
import React from 'react'
import { DefaultApiService, GenericDataType } from 'services'
import { useParams } from 'react-router-dom'
import { CGV } from 'layouts/Footer'
import { useTranslation } from 'react-i18next'

const StaticPage = () => {
  const { alias } = useParams<{ alias: string }>()
  const { t } = useTranslation('detail')

  const { data: dataCGV } = DefaultApiService.useGet<GenericDataType<CGV>>({
    url: `/cgv-cgus/${alias}`,
    options: {
      enabled: !!alias,
    },
  })

  const content = React.useMemo(() => {
    if (dataCGV?.data?.result[0]?.content) return dataCGV?.data?.result[0]?.content
    return ''
  }, [dataCGV?.data?.result])

  return (
    <Box>
      {/* <CustomBreadcrumbs /> */}
      <div className="container pt-16 pb-16">
        <Grid container className="!font-['Montserrat']" spacing={4}>
          <Grid item md={9}>
            {content ? (
              <div className="content-news" dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <Box className="text-content-title text-center font-bold text-brand-1 italic w-full">
                {t('coming_soon')}
              </Box>
            )}
          </Grid>
          <Grid item md={3} sx={{ overflow: 'hidden' }}>
            <img src={newsBgTopRight} alt="" className="w-full" />
            <img src={newsBgBotRight} alt="" className="h-[820px] w-full object-cover mt-4" />
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

export default StaticPage
