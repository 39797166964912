import { Box, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
  description?: string
}
const RoomDescription: React.FC<Props> = ({ description }) => {
  const { t } = useTranslation('detail')
  return (
    <Box>
      <Box className="text-content-12 text-brand-1 font-bold">{t('description')}</Box>
      <Box className="text-content-12 text-[#333333] mt-2">{description}</Box>
      <Divider className="!my-2" />
    </Box>
  )
}

export default RoomDescription
