import { Box, Divider } from '@mui/material'
import React from 'react'
import DateInformation, { NewsType } from './DateInformation'
import Social from '../Social'
import BookingForm from './BookingForm'
import HelperContact from './HelperContact'
import VehicleForm from './VehicleForm'
import { ItemType } from 'utils/types'

export enum InfoType {
  VEHICLE = '/xe-cho-thue',
  BDS_FOR_RENT = '/bds-cho-thue',
  BDS_FOR_SELL = '/bds-can-ban',
}

type Props = {
  data?: ItemType
  refetch?: () => void
}

const PostInformation: React.FC<Props> = ({ data, refetch }) => {
  switch (data?.type) {
    case 'MOTOR_BIKE':
    case 'CAR_4':
    case 'CAR_7':
    case 'CAR_16':
    case 'SUPER_CAR':
      return (
        <Box className="desktop:max-w-[356px] w-full flex flex-col desktop:ml-6">
          <VehicleForm data={data} />
        </Box>
      )
    case 'APARTMENT':
    case 'HOMESTAY':
    case 'VILLA':
      switch (data?.type_rental) {
        case 'DAY':
        case 'MONTH':
          return (
            <Box className="desktop:max-w-[356px] w-full flex flex-col desktop:ml-6">
              <DateInformation data={data} />
              <BookingForm data={data} />
              <HelperContact />
            </Box>
          )
        default:
          return (
            <Box className="desktop:max-w-[356px] w-full">
              <DateInformation data={data} />
              <Divider className="!my-6" />
              {/* <Social /> */}
            </Box>
          )
      }

    default:
      return (
        <Box className="desktop:max-w-[356px] w-full">
          <DateInformation data={data} />
          <Divider className="!my-6" />
          <Social
            data={data}
            refetch={() => {
              refetch?.()
            }}
          />
        </Box>
      )
  }
}

export default PostInformation
