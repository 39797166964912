import { Box } from '@mui/material'
import { ItemType } from 'utils/types'
import Filter from '../../components/Filter'
import { ApiParamsProps, FlashSaleComingApiService, GenericDataType } from '../../services'
import BDSNews from './components/BDSNews'
import Feedback from './components/Feedback'
import DiscoveryNearby from './components/DiscoveryNearby'
import FavoritePlace from './components/FavoritePlace'
import FlashSaleInDay from './components/FlashSaleInDay'
import HouseTypes from './components/HouseTypes'
import LongTimePlace from './components/LongTimePlace'
import Partner from './components/Partner'
import ShortTimePlace from './components/ShortTimePlace'
import VehicleForRent from './components/VehicleForRent'
import ViewedPlaces from './components/ViewedPlaces'

export type FlashSaleType = {
  result: {
    title: string
    icon: string
    video: string
    time_sale: string
    has_sale: boolean
    data: GenericDataType<ItemType>
  }[]
}

const HomePage = () => {
  const {
    data: dataFlashSale,
    isLoading: isLoadingFlashSale,
    refetch,
  } = FlashSaleComingApiService.useGet<FlashSaleType>({
    params: { commodity_type: 'HOUSE', page: 1, limit: 5 } as ApiParamsProps,
  })

  return (
    <Box>
      <Filter />

      <Box className="max-w-[1440px] mx-auto px-14">
        <div className="pt-[60px] pb-[60px]">
          <img src="/images/info-banner.png" alt="" />
        </div>

        <DiscoveryNearby />
        {/* House Hot */}
        <HouseTypes />
        <Box className="flex flex-col gap-20">
          {dataFlashSale?.data?.result.map((item, index) => (
            <FlashSaleInDay
              key={index}
              counter={item?.data?.total}
              data={item?.data?.result}
              isLoading={isLoadingFlashSale}
              title={item?.title}
              timeSale={item?.time_sale}
              video={item?.video}
              refetch={refetch}
            />
          ))}
          {/* House Favorite */}
          <FavoritePlace />
          {/* House Hot Short Time*/}
          <ShortTimePlace />
          {/* House Hot Long Time*/}
          <LongTimePlace />
          {/* Vehicle For Rent*/}

          <div className="pt-[0px] pb-[0px]">
            <img src="/images/tu-van.png" alt="tu van thue nha hai phong" />
          </div>
          <VehicleForRent />
          <div className="pt-[0px] pb-[0px]">
            <img src="/images/banner-thue-xe.svg" alt="tu van thue nha hai phong" />
          </div>

          <ViewedPlaces />
          <BDSNews />
          <Feedback />
        </Box>
        <Partner />
      </Box>
    </Box>
  )
}

export default HomePage
