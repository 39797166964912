import { useRouteMatch } from 'react-router-dom'
import { getBasePath } from 'utils/functions'

function usePath() {
  const { path, ...rest } = useRouteMatch()

  return {
    path: getBasePath(path),
    ...rest,
  }
}

export default usePath
