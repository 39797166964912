import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Cookies } from 'react-cookie'
import { JWT_AUTHORIZATION, UtilsSnackbar } from 'utils/constants'
import { APP_TOKEN_LOCALKEY } from 'utils/constants/apiConstant'
import Snackbar from 'notistack/'
import { ErrorType } from 'pages/DetailPage/components/PostInformation/BookingForm'

export interface Response<T> {
  code: number
  message: string
  status: boolean
  data: T
  id?: number
}

class AxiosClient {
  private readonly axiosInstance: AxiosInstance
  static instance: AxiosClient

  cookies = new Cookies()
  isRefreshing = false

  static getInstance() {
    if (!AxiosClient.instance) {
      AxiosClient.instance = new AxiosClient()
    }
    return AxiosClient.instance
  }

  public constructor() {
    this.axiosInstance = axios.create({
      headers: {
        'content-type': 'application/json',
      },
    })

    this._initializeInterceptor()
  }

  private _initializeInterceptor = () => {
    this.axiosInstance.interceptors.request.use(this._handleRequest, this._handleError)
    this.axiosInstance.interceptors.response.use(this._handleResponse, this._handleError)
  }

  private _handleRequest = async (config: AxiosRequestConfig<any>) => {
    const token = localStorage.getItem(APP_TOKEN_LOCALKEY)
    let parsedToken
    if (token) {
      parsedToken = JSON.parse(token)
    }
    if (parsedToken && config.headers) {
      config.headers.Authorization = `Bearer ${parsedToken}`
    }
    return config
  }

  private _handleResponse = (response: AxiosResponse) => {
    if (['image/png'].includes(response.headers['content-type'])) return response
    if (response.data) return response.data
    return response
  }

  _handleRefreshToken = async () => {
    const refreshUrl = process.env.REACT_APP_API_AUTH_ENDPOINT
    const refreshToken = this.cookies.get(JWT_AUTHORIZATION)
    try {
      const response = await this.axiosInstance.post(
        `${refreshUrl}/refresh-token`,
        { token: refreshToken },
        { headers: { Authorization: '' } },
      )
      const data = response?.data?.data?.result[0]
      return data
    } catch (e) {
      localStorage.removeItem(APP_TOKEN_LOCALKEY)
      this.cookies.remove(JWT_AUTHORIZATION)
    }
  }

  private _handleError = async (error: AxiosError<ErrorType>) => {
    const originalRequest = error?.config
    const status = error?.response?.status
    const message = error?.response?.data?.data?.message || ''
    UtilsSnackbar.error(message)
    if (status === 401) {
      if (!this.isRefreshing) {
        this.isRefreshing = true
        const newToken = await this._handleRefreshToken()
        if (newToken) {
          this.isRefreshing = false
          const accessToken = newToken?.accessToken
          if (!!accessToken) {
            localStorage.setItem(APP_TOKEN_LOCALKEY, JSON.stringify(accessToken))
          }
        }
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        const newToken = localStorage.getItem(APP_TOKEN_LOCALKEY)
        if (originalRequest.headers) {
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken
          resolve(axios(originalRequest))
        }
      })
      return retryOrigReq
    }
    if (status === 500) {
      localStorage.removeItem(APP_TOKEN_LOCALKEY)
      this.cookies.remove(JWT_AUTHORIZATION)
    }
    return Promise.reject(error)
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.post(url, data, config)
  }

  get<T>(url: string, config?: AxiosRequestConfig, isMock?: boolean): Promise<T> {
    const cf: AxiosRequestConfig = config ?? { headers: {} }
    if (!cf.headers) {
      cf.headers = { ENABLE_MOCK: isMock || false }
    }
    return this.axiosInstance.get(url, { ...cf })
  }

  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.put(url, data, config)
  }

  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.patch(url, data, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.delete(url, config)
  }
}

export default AxiosClient.getInstance()
