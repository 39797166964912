import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
  const { t } = useTranslation('detail')
  return (
    <Box className="min-h-[500px] flex flex-col items-center justify-center">
      <Box className="text-brand-1 text-[80px] font-bold text-center">404</Box>
      <Box className="text-brand-1 text-content-18 font-bold text-center">{t('not_found')}</Box>
    </Box>
  )
}

export default NotFoundPage
