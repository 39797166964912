import { Close } from '@mui/icons-material'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import { enforceFormat, isModifierKey } from 'utils/functions'
import { AddressProfile } from 'layouts/components/LoginModal/StepSignIn'
import { DefaultApiService, GenericDataType } from 'services'
import { OptionsAddress } from 'components/Filter'
import { Autocomplete, TextField } from '@mui/material'

export enum ModalAddressType {
  ADD,
  EDIT,
  DELETE,
}

type Props = {
  isOpen: boolean
  onClose?: () => void
  type?: ModalAddressType
  onSubmit?: (type: ModalAddressType, value: { [k: string]: any }) => void
  data?: AddressProfile
}

const ModalAddress: React.FC<Props> = ({ isOpen, onClose, type, onSubmit, data }) => {
  const { t } = useTranslation('profile')
  const [phone, setPhone] = React.useState<string>('')
  const [district, setDistrict] = React.useState<string | undefined>('')
  const [ward, setWard] = React.useState<string | undefined>('')
  const [isDefault, setIsDefault] = React.useState<boolean>(false)

  const handleChangeValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isModifierKey(event)) return
    const target = event.target as HTMLInputElement
    setPhone(target.value)
  }

  const {
    data: dataDistrict,
    refetch,
    isSuccess: isSuccessDistrict,
  } = DefaultApiService.useGet<GenericDataType<OptionsAddress>>({
    url: '/cadastral/district/31',
    options: {
      enabled: isOpen,
    },
  })

  const { data: dataWard, isSuccess: isSuccessWard } = DefaultApiService.useGet<GenericDataType<OptionsAddress>>({
    url: `/cadastral/ward/${district}`,
    options: {
      enabled: !!district && isOpen,
    },
  })

  const optionsDistrict: { label: string; value: string }[] = React.useMemo(() => {
    if (dataDistrict?.data?.result) {
      return dataDistrict?.data?.result?.map(item => ({ label: item?.name_with_type, value: item?.code }))
    }
    return []
  }, [dataDistrict?.data?.result])

  const optionsWard: { label: string; value: string }[] = React.useMemo(() => {
    if (dataWard?.data?.result) {
      return dataWard?.data?.result?.map(item => ({ label: item?.name_with_type, value: item?.code }))
    }
    return []
  }, [dataWard?.data?.result])

  const defaultDistrict = () => optionsDistrict?.find(x => x.value == district)
  const defaultWard = () => optionsWard?.find(x => x.value == ward)

  const listInput = React.useMemo(() => {
    return [
      { label: t('name'), name: 'name', defaultValue: type === ModalAddressType.ADD ? '' : data?.name },
      { label: t('phone_number'), name: 'phone', defaultValue: type === ModalAddressType.ADD ? '' : data?.phone },
      { label: t('address'), name: 'address', defaultValue: type === ModalAddressType.ADD ? '' : data?.address },
    ]
  }, [data?.address, data?.name, data?.phone, t, type])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    const totalData = {
      ...formProps,
      ward: ward,
      district: district,
      province: '31',
      is_default: formProps?.is_default === 'on',
      longitude: 0,
      latitude: 0,
    }
    onSubmit?.(type === ModalAddressType.ADD ? ModalAddressType.ADD : ModalAddressType.EDIT, totalData)
  }

  React.useEffect(() => {
    if (isOpen) {
      refetch()
      if (type === ModalAddressType.EDIT) {
        setIsDefault(data?.is_default || false)
        setDistrict(data?.district)
        setWard(data?.ward)
      } else {
        setDistrict('')
        setWard('')
        setIsDefault(false)
      }
    }
  }, [data?.district, data?.is_default, data?.ward, isOpen, refetch, type])

  React.useEffect(() => {
    if (isOpen) {
      if (type === ModalAddressType.EDIT) {
        if (isSuccessDistrict) {
          setDistrict(data?.district)
        }
        if (isSuccessWard) {
          setWard(data?.ward)
        }
      }
    }
  }, [data?.district, data?.ward, isOpen, isSuccessDistrict, isSuccessWard, type])

  if (type === ModalAddressType.EDIT && !optionsDistrict.length) return null
  if (type === ModalAddressType.EDIT && !optionsWard.length) return null

  switch (type) {
    case ModalAddressType.ADD:
    case ModalAddressType.EDIT:
      return (
        <Modal open={isOpen}>
          <Box
            className="
              relative
              top-[50%] 
              left-[50%] 
              max-w-[320px] 
              tablet:max-w-[520px] 
              translate-x-[-50%] 
              translate-y-[-50%] 
              w-full
              flex
              gap-4 
              bg-white 
              outline-none
              rounded-2xl
              "
          >
            <IconButton className="!absolute top-2 right-2 !bg-white" size="small" onClick={onClose}>
              <Close />
            </IconButton>
            <Box className="w-full p-8" component={'form'} onSubmit={handleSubmit}>
              <Box className="text-content-title text-black-1 font-bold text-center">
                {type === ModalAddressType.ADD ? t('add_address') : t('edit_address')}
              </Box>
              <Box className="text-content-16 text-black-1 font-bold">{t('add_new_address')}</Box>
              {listInput.map((item, index) => (
                <Box key={index} className="flex items-center justify-between my-4">
                  <OutlinedInput
                    className="w-full"
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px 16px',
                        fontSize: '14px',
                      },
                    }}
                    defaultValue={item?.defaultValue}
                    inputProps={item.name === 'phone' ? { maxLength: 12 } : {}}
                    placeholder={item.label}
                    name={item.name}
                    onKeyUp={item.name === 'phone' ? handleChangeValue : () => {}}
                    onKeyDown={item.name === 'phone' ? enforceFormat : () => {}}
                    required
                  />
                </Box>
              ))}
              <Box className="flex flex-wrap items-center justify-between w-full">
                <Box className="flex flex-wrap items-center gap-4 w-full mb-4">
                  {optionsDistrict && !!optionsDistrict?.length && (
                    <Autocomplete
                      disablePortal
                      className="tablet:max-w-[48%] w-full"
                      options={optionsDistrict}
                      value={defaultDistrict()}
                      onChange={(e, v) => setDistrict(v?.value)}
                      sx={{
                        '& .MuiInputBase-root': {
                          padding: '2px 14px',
                          borderRadius: '4px',
                        },
                        'input::placeholder': { fontStyle: 'italic', fontSize: '14px' },
                      }}
                      isOptionEqualToValue={(option: any, val: any) => option?.value === val?.value}
                      renderInput={params => (
                        <TextField {...params} placeholder={t('district') || ''} name={'district'} required />
                      )}
                    />
                  )}
                  {optionsWard && !!optionsWard?.length && (
                    <Autocomplete
                      disablePortal
                      className="tablet:max-w-[48%] w-full"
                      options={optionsWard}
                      value={defaultWard()}
                      onChange={(e, v) => setWard(v?.value)}
                      sx={{
                        '& .MuiInputBase-root': {
                          padding: '2px 14px',
                          borderRadius: '4px',
                        },
                        'input::placeholder': { fontStyle: 'italic', fontSize: '14px' },
                      }}
                      isOptionEqualToValue={(option: any, val: any) => option?.value === val?.value}
                      renderInput={params => (
                        <TextField {...params} placeholder={t('ward') || ''} name={'ward'} required />
                      )}
                    />
                  )}
                </Box>
              </Box>
              <Box className="flex flex-wrap justify-between">
                <FormControlLabel
                  control={<Checkbox />}
                  name="is_default"
                  checked={isDefault}
                  label={<Box className="text-content-14 text-black-1">{t('set_default')}</Box>}
                  onChange={(e, _) => setIsDefault(_)}
                />
                <Button
                  type="submit"
                  className="!bg-brand-1 !normal-case !text-white !w-[120px] !text-content-14 !h-[34px] "
                >
                  {t('save')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    default:
      return (
        <Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box
            className="
        relative
        top-[50%] 
        left-[50%] 
        max-w-[320px] 
        tablet:max-w-[520px] 
        translate-x-[-50%] 
        translate-y-[-50%] 
        w-full
        
        
        bg-white 
        outline-none
        rounded-2xl
        "
          >
            <IconButton className="!absolute top-2 right-2 !bg-white" size="small" onClick={onClose}>
              <Close />
            </IconButton>
            <Box className="p-8 w-full">
              <Box className="font-bold text-content-title text-center">{t('delete_address')}</Box>
              <Box className="text-content-16 text-center my-4 text-black-1">{t('confirm_delete')}</Box>
              <Box className="flex gap-3 justify-center">
                <Button
                  onClick={() => onClose?.()}
                  className="!normal-case !text-brand-1 !border-brand-1"
                  variant="outlined"
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={() => onSubmit?.(ModalAddressType.DELETE, { id: data?.id })}
                  className="!normal-case !text-white !bg-brand-1"
                >
                  {t('delete')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
  }
}

export default ModalAddress
