import { MenuOutlined, NotificationsOutlined, PersonOutlineOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import { Avatar, Box, IconButton } from '@mui/material'
import logoImage from 'assets/images/logo.png'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { AuthApiService, GenericDataType } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { ProfileResponse } from 'layouts/components/LoginModal/StepSignIn'
import { generateMediaLink } from 'utils/functions'
import Sidebar from '../Sidebar'
import TopWidget from './components/TopWidget'

type StateType = {
  avatar?: string
  name?: string
}

const Navbar: React.FC = () => {
  const { t } = useTranslation('navbar')
  const [toggle, setToggle] = React.useState<boolean>(false)
  const { token } = useAuth()
  const { data } = AuthApiService.useGet<GenericDataType<ProfileResponse>>({
    url: '/me',
    options: { enabled: !!token },
  })
  const history = useHistory()
  const dispatch = useLoginModalDispatch()
  const loginContext = useLoginModalContext()
  const [state, setState] = React.useState<StateType>({ avatar: '', name: '' })

  const handleSignIn = React.useCallback(() => {
    dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
  }, [dispatch])

  React.useEffect(() => {
    if (!data) {
      const newState = {
        avatar: loginContext?.avatar && generateMediaLink(loginContext?.avatar),
        name: loginContext?.name,
      }
      setState(newState)
    } else {
      const newState = {
        avatar: data?.data?.result[0].avatar ? generateMediaLink(data?.data?.result[0].avatar) : loginContext?.avatar,
        name: data?.data?.result[0].name,
      }
      setState(newState)
    }
  }, [data, loginContext?.avatar, loginContext?.name])

  // const listButton = React.useMemo(() => {
  //   return [
  //     {
  //       icon: <NotificationsOutlined className="text-icon" />,
  //       text: <Box className="!text-content-14 text-black-1">{t('notification')}</Box>,
  //       href: '/',
  //     },
  //     {
  //       icon: <ShoppingCartOutlined className="text-icon" />,
  //       text: <Box className="!text-content-14 text-black-1">{t('cart')}</Box>,
  //       href: '/',
  //     },
  //   ]
  // }, [t])

  React.useEffect(() => {
    if (data?.data?.result[0]) {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          phone: data?.data?.result[0]?.phone,
          name: data?.data?.result[0]?.name,
          username: data?.data?.result[0]?.username,
          email: data?.data?.result[0]?.email,
          avatar: data?.data?.result[0]?.avatar,
          _id: data?.data?.result[0]?._id,
        },
      })
    }
  }, [data?.data?.result, dispatch])

  return (
    <Box>
      <TopWidget />
      <Box
        className="bg-white h-[72px] flex items-center justify-around"
        sx={{ filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12))' }}
      >
        <Link to="/">
          <img src={logoImage} alt="" className="w-auto h-[48px]" />
        </Link>
        <Box className="flex items-center gap-10">
          {/* {listButton.map((item, index) => (
            <Link to={item?.href || '/xxx'} key={index} className="flex items-center cursor-pointer gap-1">
              {item.icon}
              <Box>{item.text}</Box>
            </Link>
          ))} */}
          <Box
            component={'a'}
            className="!text-content-14 text-brand-1 font-bold cursor-pointer flex items-center gap-2"
            onClick={!!token ? () => history.push(ROUTES.PROFILE) : () => handleSignIn()}
          >
            {!!token ? <Avatar src={state?.avatar} /> : <PersonOutlineOutlined className="text-icon" />}
            <Box>{!!token ? state?.name : t('sign_up')}</Box>
          </Box>
          <IconButton className="flex items-center cursor-pointer gap-1" onClick={() => setToggle(true)}>
            <MenuOutlined className=" text-black-base" />
          </IconButton>
        </Box>
      </Box>
      <Sidebar open={toggle} onClose={() => setToggle(false)} />
    </Box>
  )
}

export default Navbar
