import { Close } from '@mui/icons-material'
import { Avatar, Box, Button, CircularProgress, Grid, IconButton } from '@mui/material'
import ImageIcon from 'assets/icons/image.icon'
import ProfileIcon from 'assets/icons/profile.icon'
import newsBgBotRight from 'assets/images/bgbotright.png'
import newsBgTopRight from 'assets/images/bgtopright.png'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import Line from 'components/Line'
import Title from 'components/Title'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading'
import { useParams } from 'react-router-dom'
import {
  API_SERVICE_TYPE,
  ApiParamsProps,
  CommentApiService,
  GenericDataType,
  GetApiMethodInstance,
  NewsApiService,
} from 'services'
import { dataURItoBlob, generateMediaLink } from 'utils/functions'
import { ItemType } from 'utils/types'

function NewsDetail() {
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const { t } = useTranslation('home')
  const { id } = useParams<{ id: string }>()
  const { token } = useAuth()
  const loginContext = useLoginModalContext()

  const [images, setImages] = useState<ImageListType>([])
  const [isLoading, setLoading] = useState(false)
  const [page, setPage] = useState(5)
  const [dataResp, setDataResp] = useState<any[]>([])

  const onChange = (imageList: ImageListType) => {
    setImages(imageList)
  }

  const dispatch = useLoginModalDispatch()

  const { data: dataNewsDetail } = NewsApiService.useGet<GenericDataType<ItemType>>({
    url: `/${id}`,
    params: {} as ApiParamsProps,
    options: {
      enabled: !!id,
    },
  })

  const { data: dataComments, refetch } = CommentApiService.useGet<GenericDataType<ItemType>>({
    params: {
      news_id: id,
      page: 1,
      limit: page,
    } as ApiParamsProps,
  })

  useEffect(() => {
    if (dataComments?.data.result) {
      setDataResp(prev => {
        const diff = differenceWith(dataComments?.data.result, prev, isEqual)

        return [...prev, ...diff]
      })
    }
  }, [dataComments?.data.result])

  const handleUpComment = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }

    const valueInput = inputRef.current?.value
    if (!valueInput) return
    setLoading(true)
    if (images.length) {
      const formData = new FormData()
      images.forEach((i: ImageType) => {
        formData.append('images', dataURItoBlob(i.dataURL as string), i?.file?.name)
      })
      GetApiMethodInstance(API_SERVICE_TYPE.DEFAULT)
        .post({
          data: formData,
          url: '/uploads',
        })
        .then(async (res: any) => {
          setLoading(false)
          await GetApiMethodInstance(API_SERVICE_TYPE.COMMENT).post({
            data: {
              news_id: id,
              comment: valueInput,
              images: res.data.result[0].images,
            },
          })

          // reset images
          if (inputRef.current) {
            inputRef.current.value = ''
          }
          setImages([])
          refetch()
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    } else {
      await GetApiMethodInstance(API_SERVICE_TYPE.COMMENT).post({
        data: {
          news_id: id,
          comment: valueInput,
          images: [],
        },
      })
      // reset images
      if (inputRef.current) {
        inputRef.current.value = ''
      }
      refetch()

      setLoading(false)
    }
  }

  const handleViewMore = () => {
    setPage(prev => prev + 5)
    refetch()
  }

  return (
    <div>
      <CustomBreadcrumbs />
      <Box className="bg-brand-1 container max-w-full text-white text-[20px] pt-10 pb-10">{t('land')}</Box>
      <div className="container pt-16 pb-16">
        <Grid container className="!font-['Montserrat']" spacing={4}>
          <Grid item md={9}>
            <div
              className="content-news"
              dangerouslySetInnerHTML={{ __html: dataNewsDetail?.data.result[0].content }}
            />
          </Grid>
          <Grid item md={3} sx={{ overflow: 'hidden' }}>
            <img src={newsBgTopRight} alt="" className="w-full" />
            <img src={newsBgBotRight} alt="" className="h-[820px] w-full object-cover mt-4" />
          </Grid>
        </Grid>
        <div className="pt-16 pb-16">
          <Line />
        </div>
        <Title className="mb-4">{t('comment')}</Title>
        <div>
          <div className="flex gap-4">
            {loginContext?.avatar ? (
              <Avatar src={generateMediaLink(loginContext.avatar)} />
            ) : (
              <Avatar sx={{ bgcolor: '#EFEFEF' }}>
                <ProfileIcon />
              </Avatar>
            )}
            <div className="w-full">
              <div className="border-[1px] rounded-md p-2 border-solid border-stroke">
                <textarea
                  ref={inputRef}
                  placeholder={t('write_comment') as string}
                  className="placeholder:italic w-full outline-none resize-none"
                  rows={4}
                />
                <ImageUploading multiple value={images} onChange={onChange} maxNumber={5}>
                  {({ imageList, onImageUpload, onImageRemove }) => (
                    <div className="flex gap-2">
                      <IconButton onClick={onImageUpload}>
                        <ImageIcon />
                      </IconButton>
                      {imageList.map((image, index) => (
                        <div key={image.dataURL} className="relative">
                          <img src={image.dataURL} alt="" width="72" />
                          <div className="absolute top-[-5px] right-0">
                            <IconButton
                              sx={{
                                bgcolor: 'red',
                                color: 'white',
                                width: 16,
                                height: 16,
                                transform: 'translateY(-50%)',
                                '&:hover': {
                                  bgcolor: 'red',
                                },
                              }}
                              onClick={() => onImageRemove(index)}
                            >
                              <Close sx={{ fontSize: '12px' }} />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
          <div className="mt-12 flex justify-center">
            <Button
              className="font-semibold !text-white !text-content-12 !bg-brand-2 !rounded-xl !normal-case !pt-[10px] !pb-[10px] !pl-9 !pr-9"
              onClick={e => !isLoading && handleUpComment(e)}
            >
              {isLoading ? <CircularProgress size={16} /> : t('up_comment')}
            </Button>
          </div>
        </div>
        {dataResp.length ? (
          <>
            {dataResp?.map(i => (
              <>
                <div key={i._id} className="mt-12">
                  <div className="flex gap-6">
                    {i.user_avatar ? (
                      <Avatar src={generateMediaLink(i.user_avatar)} />
                    ) : (
                      <Avatar>
                        <ProfileIcon />
                      </Avatar>
                    )}
                    <div className="w-full">
                      <p className="mb-2">{i.user_name}</p>
                      <p className="text-grey-1 text-xs mb-2">{dayjs(i.created_at).format('DD/MM/YYYY hh:mm')}</p>
                      <p className="mb-6">{i.comment}</p>
                      <div className="flex gap-5">
                        {i.images.map((image: any) => (
                          <img key={image} src={generateMediaLink(image)} width={72} alt="" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}

            {dataComments?.data.result.length && dataComments?.data.total > dataComments?.data.limit ? (
              <div className="mt-12 flex justify-center">
                <Button
                  onClick={handleViewMore}
                  className="font-semibold !text-white !text-content-12 !bg-brand-2 !rounded-xl !normal-case !pt-[10px] !pb-[10px] !pl-12 !pr-12"
                >
                  {t('view_more')}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <p className="italic text-center mt-12">{t('no_comment') as string}</p>
        )}
      </div>
    </div>
  )
}

export default NewsDetail
