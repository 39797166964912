import React from 'react'
import { APP_TOKEN_LOCALKEY } from 'utils/constants/apiConstant'

const useAuth = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem(APP_TOKEN_LOCALKEY)
    if (tokenString) {
      const userToken = JSON.parse(tokenString)
      return userToken
    }
    return null
  }

  const [token, setToken] = React.useState<string>(getToken())

  const saveToken = (userToken: any) => {
    localStorage.setItem(APP_TOKEN_LOCALKEY, JSON.stringify(userToken))
    setToken(userToken.token)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    setToken(getToken())
  })

  return {
    getToken,
    setToken: saveToken,
    token,
  }
}

export default useAuth
