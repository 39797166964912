/* eslint-disable prettier/prettier */
import { Box, Button, Divider, MenuItem, Select, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultApiService, GenericDataType, OrdersApiService } from 'services'
import { DateDiff, convertPrice, getDateString } from 'utils/functions'
import { ItemType } from 'utils/types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { RedeemOutlined } from '@mui/icons-material'
import { rangeTime } from 'utils/constants'
import useAuth from 'hooks/useAuth'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'

type Props = {
  data?: ItemType
}

type Promo = {
  _id: string
  name: string
  code: string
  amount: number
  is_percent: boolean
  quantity: number
  description: string
  expired: number
}

type DataPre = {
  commodity_id: string
  commodity_type: string
  rental_period: number
  from_date: string
  discount: number
  type_rental: string
  price: number
  deposit_percent: number
  province: string
  district: string
  ward: string
  to_date: string
  total: number
  deposit_money: number
  promotion_code: string
  service_fee: number
  insurance_fee: number
  overtime_fee: number
  cleaning_fee: number
  deodorization_fee: number
  discount_type: number
  discount_amount: number
  sub_total: number
}

type ErrorType = {
  data: {
    error_code: string
    message: string
  }
}

const VehicleForm: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('detail')
  const [promotion, setPromotion] = React.useState<string>('')
  const [startDate, setStartDate] = React.useState<Date>(new Date())
  const [endDate, setEndDate] = React.useState<Date>(new Date())
  const [startTime, setFromTime] = React.useState<string>('00:00')
  const [endTime, setEndTime] = React.useState<string>('00:00')
  const { token } = useAuth()
  const dispatch = useLoginModalDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { data: dataPromos, isLoading } = DefaultApiService.useGet<GenericDataType<Promo>>({ url: '/promotions' })
  const { data: dataPrePay, mutate } = OrdersApiService.usePost<GenericDataType<DataPre>>({ url: '/pre' })
  const {
    data: dataRequest,
    isLoading: isLoadingRequest,
    mutate: mutateRequest,
    error: errorRequest,
  } = OrdersApiService.usePost<GenericDataType<DataPre>>({ url: '' })

  const listPriceOfProduct = React.useMemo(() => {
    return [
      {
        label: t('rent_fee'),
        value: `${DateDiff.inDays(startDate, endDate) + 1} x ${convertPrice(dataPrePay?.data?.result[0]?.price)} ${t(
          'per_day',
        )}`,
      },
      {
        label: t('service_fee'),
        value: `${DateDiff.inDays(startDate, endDate) + 1} x ${convertPrice(
          dataPrePay?.data?.result[0]?.service_fee,
        )} ${t('per_day')}`,
      },
      {
        label: t('insurance_fee'),
        value: `${DateDiff.inDays(startDate, endDate) + 1} x ${convertPrice(
          dataPrePay?.data?.result[0]?.insurance_fee,
        )} ${t('per_day')}`,
      },
    ]
  }, [dataPrePay?.data?.result, endDate, startDate, t])

  const listExtendFee = React.useMemo(() => {
    return [
      {
        label: t('overtime_fee'),
        value: convertPrice(data?.overtime_fee) + t('per_hour'),
      },
      {
        label: t('clean_fee'),
        value: convertPrice(data?.cleaning_fee),
      },
      {
        label: t('smell_fee'),
        value: convertPrice(data?.deodorization_fee),
      },
    ]
  }, [data?.cleaning_fee, data?.deodorization_fee, data?.overtime_fee, t])

  const handleChangeStartDate = (date: Date | null) => {
    if (date && endDate) {
      if (endDate?.getTime() < date?.getTime()) {
        setStartDate(date)
        setEndDate(date)
      }
    }
  }

  const discount = React.useMemo(() => {
    return dataPrePay?.data?.result[0]?.discount_amount
  }, [dataPrePay?.data?.result])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }
    if (startDate && endDate) {
      const period = DateDiff.inDays(startDate, endDate) + 1
      const requestProps = {
        commodity_id: data?._id,
        commodity_type: 'VEHICLE',
        from_date: getDateString(startDate, 'yyyy-mm-dd'),
        rental_period: period,
        from_time: startTime,
        to_time: endTime,
        province: data?.province,
        district: data?.district,
        ward: data?.ward,
      }
      mutateRequest({
        data: requestProps,
      })
    }
  }

  React.useEffect(() => {
    if (startDate && endDate) {
      const period = DateDiff.inDays(startDate, endDate) + 1
      mutate({
        data: {
          commodity_id: data?._id,
          commodity_type: 'VEHICLE',
          promotion_code: promotion,
          rental_period: period,
          from_date: getDateString(startDate, 'yyyy-mm-dd'),
        },
      })
    }
  }, [data?._id, endDate, mutate, promotion, startDate])

  React.useEffect(() => {
    if (dataRequest && dataRequest?.data?.result) {
      enqueueSnackbar(t('request_success'), { variant: 'success' })
    }
    if (errorRequest) {
      const errorResponse = errorRequest as AxiosError<ErrorType>
      if (errorResponse?.response?.data?.data?.error_code === 'ORDER_COMMODITY_RENTED') {
        enqueueSnackbar(t('request_error_exist_renter'), { variant: 'error' })
      }
    }
  }, [dataRequest, enqueueSnackbar, errorRequest, t])

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <Box>
        <Box component="span" className="text-content-12">
          {t('price_to_rent')}
        </Box>
        <Box component="span" className="text-brand-1 font-bold">
          {convertPrice(data?.sale_price ? data?.sale_price : data?.price)} {t('per_day')}
        </Box>
      </Box>
      <Box className="border-brand-1 rounded-xl border-[1px] flex flex-wrap mt-2 min-w-[300px]">
        <Box className="tablet:border-r-[1px] border-brand-1 w-[49%] min-w-[150px] p-2 text-content-12">
          <Box>{t('take_vehicle')}</Box>
          <Box className="font-bold mt-1">
            <Stack direction="row" justifyContent="space-between">
              <DatePicker
                selected={startDate}
                onChange={handleChangeStartDate}
                selectsStart
                minDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                disabled={isLoading}
                dateFormat="dd/MM/yyyy"
                name="start-date"
                className="text-content-14  px-2 w-full border-none outline-none"
              />
              <Select
                size="small"
                className="w-full"
                sx={{
                  width: '50px',
                  '& svg, fieldset': { display: 'none' },
                  '& .MuiSelect-select': {
                    padding: '0',
                    paddingRight: '0 !important',
                    width: '50px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    border: 0,
                  },
                }}
                value={startTime}
                onChange={e => setFromTime(e.target.value)}
              >
                {rangeTime.map((item, index) => (
                  <MenuItem key={index} className="!text-content-14" value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Box>
        </Box>
        <Box className="w-[49%] min-w-[150px] p-2 text-content-12">
          <Box>{t('vehicle_back')}</Box>
          <Box className="font-bold mt-1">
            <Stack direction="row" justifyContent="space-between">
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date as Date)}
                selectsEnd
                disabled={isLoading}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
                name="end-date"
                className="text-content-14 border-[1px] border-grey-1 rounded-[4px] px-2 w-full  border-none outline-none"
              />
              <Select
                size="small"
                className="w-full border-none"
                sx={{
                  width: '50px',
                  '& svg, fieldset': { display: 'none' },
                  '& .MuiSelect-select': {
                    padding: '0',
                    paddingRight: '0 !important',
                    width: '50px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    border: 0,
                  },
                }}
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
              >
                {rangeTime.map((item, index) => (
                  <MenuItem key={index} className="!text-content-14" value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Divider className="!my-3" />
      <Box className="text-grey-1 text-content-10">{t('place_give_vehicle')}</Box>
      <Box className="font-bold text-content-12">{data?.address_path}</Box>
      <Box className="text-grey-1 text-content-10">{t('pre_pay_time')}</Box>
      <Divider className="!my-3" />
      {listPriceOfProduct.map((item, index) => (
        <Box key={index} className="flex flex-wrap justify-between">
          <Box className="text-content-12">{item.label}</Box>
          <Box className="font-bold text-content-12">{item.value}</Box>
        </Box>
      ))}
      <Divider className="!my-3" />
      <Box>
        <Box className="flex flex-wrap justify-between">
          <Box className="text-content-12">{t('original_price')}</Box>
          <Box className="font-bold text-content-12">
            {convertPrice(dataPrePay?.data?.result[0]?.sub_total)} {t('per_day')}
          </Box>
        </Box>
        <Box className="font-bold text-content-12">{t('list_voucher')}</Box>
        <Box className="border-[1px] border-brand-1 p-2 rounded-lg max-h-[200px] overflow-y-auto mt-2">
          {dataPromos?.data?.result?.length ? (
            dataPromos?.data?.result?.map((item, index) => (
              <Box key={index} className="flex items-center justify-between my-1">
                <Box className="flex items-center !text-brand-1">
                  <RedeemOutlined />
                  <Box className="font-bold ml-2">{item?.name}</Box>
                </Box>
                <Button
                  onClick={() => setPromotion(item?.code)}
                  className="!normal-case !text-brand-1 !text-content-12 !border-brand-1"
                  variant="outlined"
                >
                  {t('apply')}
                </Button>
              </Box>
            ))
          ) : (
            <Box className="text-brand-1 text-content-12 text-center">{t('no_voucher_available')}</Box>
          )}
        </Box>
      </Box>
      <Divider className="!my-3" />
      <Box></Box>
      <Box className="flex flex-wrap justify-between">
        <Box className="text-content-12">{t('discount')}</Box>
        <Box className="text-content-12 line-through text-grey-1">{convertPrice(discount) || 0}</Box>
      </Box>
      <Box className="flex flex-wrap justify-between">
        <Box className="text-content-12">{t('total_price')}</Box>
        <Box className="font-bold text-content-12">{convertPrice(dataPrePay?.data?.result[0]?.total)}</Box>
      </Box>
      <Button
        type="submit"
        className="h-8 !rounded-lg !text-white !normal-case !bg-brand-1 !font-[600] !text-content-12 !mt-4 disabled:!bg-grey-1 w-full"
        disabled={isLoading}
      >
        {t('send_request_book_vehicle')}
      </Button>
      <Divider className="!my-3" />
      <Box className="text-content-14 font-bold text-brand-1">{t('extend_service_fee')}</Box>
      {listExtendFee?.map((item, index) => (
        <Box key={index} className="flex flex-wrap justify-between">
          <Box className="text-content-12">{item.label}</Box>
          <Box className="font-bold text-content-12">{item.value}</Box>
        </Box>
      ))}
    </Box>
  )
}

export default VehicleForm
