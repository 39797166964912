import { Box } from '@mui/material'
import CardItem from 'components/CardItem'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { GenericDataType, HouseApiService } from 'services'
import { CACHE_DATA } from 'utils/constants'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

const ViewedPlaces = () => {
  const { t } = useTranslation('home')
  const history = useHistory()
  const [data, setData] = React.useState<ItemType[]>([])
  const { token } = useAuth()
  const {
    data: dataHouse,
    isLoading: isLoadingHouse,
    refetch,
  } = HouseApiService.useGet<GenericDataType<ItemType>>({
    url: '/revert-seen',
    params: {
      limit: 10,
      page: 1,
    },
    options: {
      enabled: !!token,
    },
  })

  React.useEffect(() => {
    if (!token) {
      const localData = localStorage.getItem(CACHE_DATA)
      if (localData) {
        const parsedData = JSON.parse(localData)
        if (parsedData && parsedData.length) {
          setData(parsedData.slice(0, 5).reverse())
        } else setData([])
      }
    } else {
      if (dataHouse?.data?.result?.length) {
        setData(dataHouse?.data?.result)
      } else setData([])
    }
  }, [dataHouse?.data?.result, token])

  if (!data?.length) return null

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        title={t('viewed_places')}
        type={TitleWithRouteType.BACKGROUND_TITLE}
        isNoButton
      />
      <Box className="flex flex-wrap gap-5">
        {data?.map((item, index) => (
          <CardItem
            id={item._id}
            key={index}
            type={item.type}
            name={item.name}
            image={item?.images?.[0]?.image || ''}
            bathCount={item.n_bathroom}
            bedCount={item.n_bedroom}
            updatedTime={item.updated_at}
            price={item?.sale_price || item?.price}
            isLiked={item.is_favorite}
            onRefetchAfterLike={() => refetch()}
            href={generateHref({ item: item })}
          />
        ))}
      </Box>
      {!data.length && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default ViewedPlaces
