const ROUTES = {
  HOME: '/',
  VEHICLE: '/cho-thue-xe-tu-lai',
  VEHICLE_DETAIL: '/cho-thue-xe-tu-lai/:alias/:id',
  HOUSE: '/bat-dong-san',
  FOR_RENT: '/cho-thue',
  NEED_SELL: '/can-ban',
  PROJECT: '/du-an',
  HOUSE_HOT: '/bat-dong-san/flash-sale',
  HOUSE_FAVORITE: '/bat-dong-san/yeu-thich',
  BDS_FOR_SELL: '/bat-dong-san',
  HOUSE_DETAIL: '/bat-dong-san/:alias/:id',
  BDS_FOR_RENT_DETAIL: '/bat-dong-san/:alias/:id',
  FLASH_SALE_IN_DAY: '/flash-sales',
  FLASH_SALE_IN_DAY_COMMING: '/flash-sales/comming',
  PROFILE: '/profile',
  NOT_FOUND: '/not-found',
  NEWS: '/tin-tuc',
  PROFILE_ADDRESS: '/address',
  PROFILE_RESET_PASSWORD: '/reset-password',
  NEWS_DETAIL: '/tin-tuc/:alias/:id',
  FAVORITE_LIST: '/favorite',
  TRANG_PAGE: '/trang',
  TRANG_DETAIL_PAGE: '/trang/:alias',
}

export default ROUTES
