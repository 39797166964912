import { BedOutlined, FavoriteBorder, FavoriteOutlined, ShowerOutlined } from '@mui/icons-material'
import { Box, IconButton, styled } from '@mui/material'
import DefaultRoomImage from 'assets/images/DefaultRoomImage.png'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import { GeneralUtil } from 'helpers/GeneralUtil'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { GenericDataType, HouseApiService, VehicleApiService } from 'services'
import { typeCard } from 'utils/constants'
import { ProductType, generateMediaLink } from 'utils/functions'
import { ItemType } from 'utils/types'

type Props = {
  id: string
  type?: string
  name?: string
  price?: number
  image?: string | null
  type_rental?: string
  discount?: string
  isPropose?: boolean
  bedCount?: number
  bathCount?: number
  updatedTime?: string
  isLiked?: boolean
  href?: string
  onRefetchAfterLike?: () => void
}

const CustomCard = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
})

const CardItem: React.FC<Props> = ({
  id,
  type,
  name,
  image,
  price,
  type_rental,
  bedCount = 0,
  bathCount = 0,
  isPropose,
  updatedTime,
  isLiked,
  discount,
  onRefetchAfterLike,
  href,
}) => {
  const { t } = useTranslation('home')

  const { mutate: mutateHouse, isSuccess: isSuccessHouse } = HouseApiService.usePatch<GenericDataType<ItemType>>({
    url: `/${id}/favorite`,
  })

  const { mutate: mutateVehicle, isSuccess: isSuccessVehicle } = VehicleApiService.usePatch<GenericDataType<ItemType>>({
    url: `/${id}/favorite`,
  })

  const { token } = useAuth()
  const dispatch = useLoginModalDispatch()

  const calculateTime = React.useMemo(() => {
    if (updatedTime) {
      const time = (Date.now() - Date.parse(updatedTime)) / (1000 * 60 * 60 * 24)
      return Math.ceil(time)
    }
  }, [updatedTime])

  const titleType = React.useMemo(() => {
    const valueType = Object.values(typeCard)
      .flat()
      .find(x => x === type)
    return t(valueType?.toLowerCase() || '')
  }, [t, type])

  const handleClickLike = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }
    if (ProductType(type) === 'HOUSE') {
      mutateHouse({
        data: {},
      })
    } else
      mutateVehicle({
        data: {},
      })
  }

  React.useEffect(() => {
    if (isSuccessHouse || isSuccessVehicle) {
      onRefetchAfterLike?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessHouse, isSuccessVehicle])

  return (
    <CustomCard
      className="min-w-[248px] w-[248px] h-[437px] !rounded-xl !p-3 cursor-pointer !block !text-left relative !bg-white"
      sx={{ boxShadow: '0px 0px 12px rgba(15, 61, 145, 0.12)' }}
      to={href || '/'}
    >
      <Box>
        {discount && (
          <Box
            className="
            absolute 
            top-0 
            right-0 
            h-14 
            bg-access-red 
            z-10 
            leading-[56px] 
            rounded-tr-xl 
            rounded-bl-xl 
            text-content-14
            text-white 
            font-bold 
            px-3
            min-w-[56px] 
            text-center
          "
          >
            {discount}
          </Box>
        )}
        {isPropose && (
          <Box
            className="
            absolute 
            top-0 
            right-0 
            h-14 
            bg-access-green 
            z-10 
            leading-[56px] 
            rounded-tr-xl 
            rounded-bl-xl 
            text-content-14 
            text-white 
            font-bold 
            px-3
          "
          >
            {t('propose')}
          </Box>
        )}
        <Box className="flex flex-col justify-between h-full">
          <Box>
            <Box component={'div'} className="relative">
              <Box
                component={'img'}
                sx={{ objectFit: 'cover' }}
                src={image ? generateMediaLink(image) : DefaultRoomImage}
                className="rounded-2xl w-[225px] h-[225px] pointer-events-none"
              />
              <IconButton
                onClick={e => handleClickLike(e)}
                className="!absolute bottom-2 right-2 !bg-[#FFFFFF60] !p-1 rounded-full"
              >
                {isLiked ? (
                  <FavoriteOutlined className={'text-brand-1'} />
                ) : (
                  <FavoriteBorder className={'text-black-1'} />
                )}
              </IconButton>
            </Box>
            <Box className="text-brand-1 text-content-12 mt-3">{titleType}</Box>
            <Box className="text-black-1 text-content-14 mt-1">{name}</Box>
            <Box
              className="text-brand-1 mt-2  text-ellipsis overflow-hidden whitespace-nowrap w-full"
              title={price?.toString() + 'đ' || ''}
            >
              <Box component="span" className="font-bold text-content-title">
                {GeneralUtil.toMoney(Number(price || 0))}
              </Box>
              {ProductType(type) === 'HOUSE' && (
                <Box component="span" className="text-content-14">
                  {type_rental === 'DAY' ? t('per_day') : type_rental === 'FOREVER' ? '' : t('per_month')}
                </Box>
              )}
            </Box>
            {ProductType(type) === 'HOUSE' && (
              <Box className="flex gap-6 mt-3">
                {bathCount >= 0 && (
                  <Box className="flex gap-2 justify-center items-center">
                    <Box component="span" className="text-content-12">
                      {bathCount}
                    </Box>
                    <Box className="bg-[#F0F2F5] rounded-full w-[34px] h-[34px] flex justify-center items-center">
                      <ShowerOutlined className="text-icon" />
                    </Box>
                  </Box>
                )}
                {bedCount >= 0 && (
                  <Box className="flex gap-2 justify-center items-center">
                    <Box component="span" className="text-content-12">
                      {bedCount}
                    </Box>
                    <Box className="bg-[#F0F2F5] rounded-full w-[34px] h-[34px] flex justify-center items-center">
                      <BedOutlined className="text-icon" />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box className="text-content-10 text-icon">{(calculateTime || 0) + t('days_ago')}</Box>
        </Box>
      </Box>
    </CustomCard>
  )
}

export default CardItem
