import { Box, CircularProgress } from '@mui/material'
import CardCarousel, { CarouselType } from 'components/CardCarousel'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ApiParamsProps, GenericDataType, HouseHotApiService } from 'services'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import ROUTES from 'utils/constants/routesConstant'
import { generateMediaLink } from 'utils/functions'
import { ItemType } from 'utils/types'

const HouseTypes = () => {
  const { data, isLoading } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 9,
    } as ApiParamsProps,
  })
  const { t } = useTranslation('home')

  const filteredData = React.useMemo(() => {
    if (data?.data?.result.length) {
      return data?.data?.result
    }
    return []
  }, [data])

  return (
    <Box className="mt-4">
      <TitleWithRoute
        buttonText={t('go_discover_now')}
        href={`${ROUTES.HOUSE_HOT}?page=1`}
        title={t('discovery_house_type_title')}
        type={TitleWithRouteType.BACKGROUND_BUTTON}
        isNoButton
      />
      <Box className="text-content-16 text-icon text-center">{t('discovery_house_type_description')}</Box>
      {isLoading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="mt-8">
          <Swiper
            modules={[Pagination]}
            spaceBetween={18}
            slidesPerView={3}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>'
              },
            }}
          >
            {filteredData?.map((item, index) => {
              return (
                <SwiperSlide className="!h-[350px]" key={index}>
                  <CardCarousel
                    type={CarouselType.HOUSE}
                    title={item.name}
                    starCount={item.avg_star}
                    description={item.description}
                    href={`${ROUTES.HOUSE}/${item.alias}/${item._id}`}
                    srcImage={generateMediaLink(item?.images[0]?.image)}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>
      )}
      {!isLoading && !data?.data?.total && (
        <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>
      )}
    </Box>
  )
}

export default HouseTypes
