import { Box, Button } from '@mui/material'
import { AxiosError } from 'axios'
import MuiOtpInput from 'components/OTPInput'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GenericDataType, OTPApiService, UserApiService } from 'services'
import { LoginProps, StepLogin } from '.'

type OTPResponse = {
  expires_in: number
  send_email: boolean
  send_sms: boolean
  v_code: string
}

type OTPVerifyResponse = {
  is_verified: boolean
}

const StepOTP: React.FC<LoginProps> = ({ onChangeStep, isForgot }) => {
  const [state, setState] = React.useState('')
  const [timer, setTimer] = React.useState<number>(0)
  const { t } = useTranslation('login')
  const dispatch = useLoginModalDispatch()

  const { data: dataForgot, mutate: mutateForgot } = UserApiService.usePost<GenericDataType<OTPResponse>>({
    url: '/password/forgot',
  })

  const { data, mutate } = OTPApiService.usePost<GenericDataType<OTPResponse>>({ url: '/send' })
  const {
    data: dataVerified,
    mutate: mutateVerified,
    error,
  } = OTPApiService.usePost<GenericDataType<OTPVerifyResponse>>({
    url: '/verify',
  })

  const errorHandle = React.useMemo(() => {
    return error as AxiosError
  }, [error])

  const loginContext = useLoginModalContext()

  React.useEffect(() => {
    const currentTime = timer
    const timerTimeout = setInterval(() => {
      if (currentTime === 0) return
      setTimer(currentTime - 1)
    }, 1000)
    return () => {
      clearInterval(timerTimeout)
    }
  }, [timer])

  const handleResentOTP = () => {
    if (loginContext?.phone) {
      if (isForgot) {
        mutateForgot({
          data: {
            username: loginContext?.phone,
          },
        })
        return
      }
      mutate({
        data: {
          phone: loginContext?.phone,
        },
      })
    }
  }

  const handleSubmitOTP = () => {
    if (loginContext?.phone) {
      console.log(loginContext)
      if (isForgot) {
        mutateVerified({
          data: {
            v_code: loginContext?.v_code,
            otp_code: state,
            otp_type: 'FORGOT_PASSWORD',
          },
        })
        return
      }
      mutateVerified({
        data: {
          v_code: loginContext.v_code, //loginContext?.phone,
          otp_code: state,
        },
      })
    }
  }

  React.useEffect(() => {
    if (loginContext?.otp_expire) {
      setTimer(loginContext?.otp_expire)
    }

    if (data && data?.data?.result[0]?.expires_in) {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { otp_expire: data?.data?.result[0]?.expires_in, v_code: data?.data?.result[0]?.v_code },
      })
      setTimer(data?.data?.result[0]?.expires_in)
      return
    }

    if (dataForgot && dataForgot?.data?.result[0]?.expires_in) {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { otp_expire: dataForgot?.data?.result[0]?.expires_in },
      })
      setTimer(dataForgot?.data?.result[0]?.expires_in)
      return
    }
  }, [data, dispatch, loginContext?.otp_expire, dataForgot])

  React.useEffect(() => {
    if (dataVerified && dataVerified?.data?.result[0]?.is_verified) {
      if (isForgot) {
        onChangeStep?.(StepLogin.DONE_OTP_FORGOT)
        return
      }
      onChangeStep?.(StepLogin.DONE_OTP)
    }
  }, [dataVerified, onChangeStep, isForgot])

  return (
    <Box className="p-5 w-full flex flex-col justify-center">
      <Box className="text-center text-content-16 font-bold">{t('input_otp')}</Box>
      <Box className="text-center text-content-14 max-w-[300px] mx-auto text-[#858688] mt-2">
        {t('input_otp_description')}
      </Box>
      <Box className="flex justify-center mt-7">
        <MuiOtpInput value={state} onChange={e => setState(e)} length={6} />
      </Box>
      {(errorHandle?.response?.status === 404 || errorHandle?.response?.status === 400) && (
        <Box className="text-center text-content-16 text-brand-1 mt-3">{t('error_input_otp')}</Box>
      )}
      <Button
        disabled={state.length < 6}
        onClick={handleSubmitOTP}
        className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mx-auto !mt-4 disabled:!bg-grey-1"
      >
        {t('continue')}
      </Button>
      <Button
        onClick={handleResentOTP}
        disabled={timer !== 0}
        className="!text-brand-1 !normal-case !text-content-14 w-[200px] !mx-auto !mt-2 disabled:!text-grey-1"
      >
        {t('resent_otp')} {timer ? `(${timer})` : ''}
      </Button>
    </Box>
  )
}

export default StepOTP
