import { Box, styled } from '@mui/material'
import newsBgTopRight from 'assets/images/bgtopright.png'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  image?: string
  title?: string
  href: string
}

const StyledCard = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
})

const CardNews: React.FC<Props> = ({ title, image, href = '' }) => {
  return (
    <StyledCard
      to={href}
      className="!w-[290px] !rounded-xl !h-[367px] !p-3 cursor-pointer !text-left relative !bg-white shadow-md"
    >
      <Box className="flex flex-col">
        <Box
          component={'img'}
          src={image || newsBgTopRight}
          className="pointer-events-none !w-[269px] !h-[166px] object-cover !rounded-xl"
        />
      </Box>
      <Box
        className="text-black-1 p-3 w-full text-[20px] break-words"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
        }}
        title={title}
      >
        {title}
      </Box>
    </StyledCard>
  )
}

export default CardNews
