import React from 'react'
import { useSnackbar } from 'notistack'

const SnackbarAction: (snackbarId: string | number) => React.ReactNode = (snackbarId: string | number) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <>
      <button
        onClick={() => {
          closeSnackbar(snackbarId)
        }}
      >
        Close
      </button>
    </>
  )
}

export default SnackbarAction
