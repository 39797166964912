import { AccountCircle, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericDataType, UserApiService } from 'services'
import { LoginProps, StepLogin } from '.'
import { ProfileResponse } from './StepSignIn'

const StepCreatePassword: React.FC<LoginProps> = ({ onChangeStep, isForgot }) => {
  const { t } = useTranslation('login')
  const [showPassword, setShowPassword] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [rePassword, setRePassword] = React.useState('')
  const { data: dataCreate, isLoading, mutate } = UserApiService.usePost<GenericDataType<ProfileResponse>>({})
  const { data: dataReset, mutate: mutateReset } = UserApiService.usePost<GenericDataType<ProfileResponse>>({
    url: '/password/reset',
  })
  const dispatch = useLoginModalDispatch()
  const loginContext = useLoginModalContext()

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    if (formProps?.password?.toString() !== '') {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: { password: formProps?.password?.toString() },
      })

      if (isForgot) {
        mutateReset({
          data: {
            new_password: formProps?.password?.toString(),
            v_code: loginContext?.v_code,
          },
        })

        return
      }
      onChangeStep?.(StepLogin.UPDATE_PROFILE)
    }
  }

  useEffect(() => {
    if (dataReset?.data.result) {
      onChangeStep?.(StepLogin.DONE_FORGOT)
    }
  }, [dataReset, onChangeStep])

  return (
    <Box component={'form'} className="flex flex-col w-full tablet:p-10 p-5" onSubmit={handleSubmit}>
      <Box className="text-center">
        <AccountCircle className="text-grey-1 !text-[90px]" />
        <Box className="text-black-1 text-content-title font-bold mb-4">{t('create_password')}</Box>
      </Box>
      <Box className="flex flex-col w-full gap-3 mb-4">
        <InputLabel className="text-content-14 !font-semibold !text-black-base" htmlFor="password">
          {t('create_password')}
        </InputLabel>
        <OutlinedInput
          name="password"
          required
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
          onChange={e => setPassword(e.target.value)}
        />
        <InputLabel className="text-content-14 !font-semibold !text-black-base" htmlFor="password">
          {t('re_entry_password')}
        </InputLabel>
        <OutlinedInput
          name="re_entry_password"
          type={showPassword ? 'text' : 'password'}
          required
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
          onChange={e => setRePassword(e.target.value)}
        />
      </Box>
      {password !== rePassword && !!password && (
        <Box className="text-center text-content-16 text-brand-1 ">{t('error_input_otp')}</Box>
      )}
      <Button
        type="submit"
        className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mx-auto !mt-3 disabled:!bg-grey-1"
        disabled={isLoading || password !== rePassword || (password === '' && rePassword === '')}
      >
        {t('continue')}
      </Button>
      <Box className="max-w-[358px] text-center mx-auto mt-3 text-content-14">
        {t('term_and_policy_1')}
        <Box component={'a'} target="_blank" href="/term" className="text-brand-1 font-bold">
          {t('term')}
        </Box>
        {t('term_and_policy_2')}
        <Box component={'a'} target="_blank" href="/policy" className="text-brand-1 font-bold">
          {t('policy')}
        </Box>
        {t('term_and_policy_3')}
      </Box>
    </Box>
  )
}

export default StepCreatePassword
