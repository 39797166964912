import styled from '@emotion/styled'
import { Radio, RadioProps } from '@mui/material'
import React from 'react'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 24,
  backgroundColor: '#ffffff',
  border: '1px solid #DF3E23',
  '.Mui-focusVisible &': {
    outline: '2px auto #DF3E23',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#cccccc',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundImage: '#eeeeee',
  '&:before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundColor: '#DF3E23',
    borderRadius: '100%',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#eeeeee',
  },
})

const RadioControl = (props: RadioProps) => {
  return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />
}

export default RadioControl
