import { Box, Button, CircularProgress } from '@mui/material'
import playIcon from 'assets/images/play.svg'
import vehiclesPNG from 'assets/images/vehicles.png'
import CardItem from 'components/CardItem'
import TitleWithRoute from 'components/TitleWithRoute'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, DefaultApiService, GenericDataType, VehicleSearchApiService } from 'services'
import { domain } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

enum VehicleType {
  MOTOBIKE = 'MOTOBIKE',
  CAR = 'CAR',
}

const VehicleForRent = () => {
  const [isOpen, setOpen] = useState(false)
  const { data, isLoading, refetch } = VehicleSearchApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 3,
      type: 'MOTOR_BIKE',
    } as ApiParamsProps,
  })

  const {
    data: dataForCar,
    isLoading: isLoadingForCar,
    refetch: refetchCar,
  } = VehicleSearchApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 5,
      type: 'SUPER_CAR',
    } as ApiParamsProps,
  })

  const { data: dataVideo } = DefaultApiService.useGet<GenericDataType<ItemType>>({
    url: '/master-data/category/video',
    params: {
      commodity_type: 'VEHICLE',
    },
  })

  const [selectedType, setSelectedType] = React.useState<VehicleType>(VehicleType.MOTOBIKE)
  const { t } = useTranslation('home')
  const history = useHistory()

  const renderButton = React.useCallback(() => {
    const listTitle = [
      {
        name: 'Xe máy',
        value: VehicleType.MOTOBIKE,
        disabled: isLoading,
      },
      {
        name: 'Xe ô tô',
        value: VehicleType.CAR,
        disabled: isLoadingForCar,
      },
    ]
    return listTitle.map((item, index) => (
      <Button
        key={index}
        className={`!font-bold !text-content-14 ${
          selectedType === item.value ? '!bg-brand-1 !text-white' : '!text-black-1 '
        }`}
        onClick={() => setSelectedType(item.value)}
        disabled={item.disabled}
      >
        {item.name}
      </Button>
    ))
  }, [isLoading, isLoadingForCar, selectedType])

  const filteredData = React.useMemo(() => {
    const dataSelected: GenericDataType<ItemType> | undefined =
      selectedType === VehicleType.MOTOBIKE ? data?.data : dataForCar?.data
    if (dataSelected?.result && dataSelected?.result?.length) {
      return dataSelected?.result
    } else return []
  }, [dataForCar, data, selectedType])

  const isShowEmpty = React.useMemo(() => {
    if (selectedType === VehicleType.MOTOBIKE) {
      return !isLoading && !data?.data?.total
    } else return !isLoadingForCar && !dataForCar?.data?.total
  }, [data?.data?.total, dataForCar?.data?.total, isLoading, isLoadingForCar, selectedType])

  const video = dataVideo?.data?.result?.[0]?.link

  const idVideo = React.useMemo(() => {
    if (video?.includes('youtube')) {
      return video.replace('https://www.youtube.com/watch?v=', '')
    }
  }, [video])

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        href={`${ROUTES.VEHICLE}?page=1`}
        title={
          <Box className="flex items-center gap-4">
            <Box className="text-white text-content-title font-bold bg-brand-1 px-6 py-2 rounded-tr-xl rounded-br-xl">
              {t('vehicle_for_rent')}
            </Box>
            {renderButton()}
          </Box>
        }
      />
      {isLoading || isLoadingForCar ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex flex-wrap gap-5">
          {video && (
            <Box className="w-[516px] h-[437px] flex justify-center items-center bg-black rounded-xl">
              {isOpen ? (
                <>
                  {video?.includes('youtube') ? (
                    <iframe
                      className="w-full h-full"
                      title="Youtube player"
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://youtube.com/embed/${idVideo}?autoplay=0`}
                    ></iframe>
                  ) : (
                    <video controls={true} width={'100%'} height={'100%'} className="w-full h-full">
                      <source src={domain + video} type="video/mp4" />
                      <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"></track>
                    </video>
                  )}
                </>
              ) : (
                <div className="cursor-pointer w-full h-full relative" onClick={() => setOpen(true)}>
                  <img src={vehiclesPNG} alt="" className="h-full w-full" />
                  <img
                    src={playIcon}
                    alt=""
                    className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              )}
            </Box>
          )}
          {filteredData.map((item, index) => (
            <CardItem
              id={item._id}
              key={index}
              type={item.type}
              name={item.name}
              image={item?.images?.[0]?.image || ''}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              type_rental={item.type_rental}
              updatedTime={item.updated_at}
              isLiked={item.is_favorite}
              onRefetchAfterLike={() => (selectedType === VehicleType.MOTOBIKE ? refetch() : refetchCar())}
              href={generateHref({ item: item })}
              price={item?.sale_price || item?.price}
            />
          ))}
        </Box>
      )}
      {isShowEmpty && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default VehicleForRent
