import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, InputLabel, Modal, TextField } from '@mui/material'
import { useLoginModalContext } from 'context'
import useAuth from 'hooks/useAuth'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultApiService, GenericDataType, UserApiService } from 'services'
import { ProductType, ValidateEmail, enforceFormat, isModifierKey, validatePhoneNumber } from 'utils/functions'
import { ItemType } from 'utils/types'

type Props = {
  isOpen: boolean
  onClose: () => void
  data?: ItemType
}

const ReconnectModal: React.FC<Props> = ({ isOpen, onClose, data }) => {
  const { t } = useTranslation('detail')
  const { token } = useAuth()
  const [phone, setPhone] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const [name, setName] = React.useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  const loginContext = useLoginModalContext()
  const { isLoading, isSuccess, mutate } = UserApiService.usePost<GenericDataType<any>>({ url: '/request' })

  const handleChangeValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isModifierKey(event)) return
    const target = event.target as HTMLInputElement
    setPhone(target.value)
  }

  const isDisabledButton = React.useMemo(() => {
    return !ValidateEmail(email) && !validatePhoneNumber(phone) && name === ''
  }, [email, name, phone])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    mutate({
      data: {
        name: name,
        email: email,
        phone: phone,
        receiver_id: data?.owner_id,
        commodity_id: data?._id,
        commodity_type: ProductType(data?.type),
        description: formProps?.description,
      },
    })
  }

  React.useEffect(() => {
    if (loginContext) {
      if (loginContext?.email) {
        setEmail(loginContext?.email)
      }
      if (loginContext?.name) {
        setName(loginContext?.name)
      }
      if (loginContext?.phone) {
        setPhone(loginContext?.phone)
      }
    }
  }, [loginContext, isOpen])

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t('request_success'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isSuccess, t])

  return (
    <Modal open={isOpen}>
      <Box
        className="
          relative
          top-[50%] 
          left-[50%] 
          max-w-[320px] 
          tablet:max-w-[500px] 
          translate-x-[-50%] 
          translate-y-[-50%] 
          w-full
          flex
          gap-4 
          bg-white 
          outline-none
          rounded-2xl
          "
      >
        <IconButton className="!absolute top-2 right-2 !bg-white" size="small" onClick={onClose}>
          <Close />
        </IconButton>
        <Box component="form" className="px-10 py-6 w-full" onSubmit={handleSubmit}>
          <Box className="text-content-18 font-bold">{t('contact_information')}</Box>
          <Box className="w-full flex flex-col gap-2 mt-4">
            <Box>
              <InputLabel className="text-content-14 !text-[#162A4C] " htmlFor="name">
                {t('name')}
              </InputLabel>
              <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                name="name"
                disabled={!!token || isSuccess || isLoading}
                size="small"
                required
                placeholder={t('name_placeholder') || ''}
                className="w-full"
                sx={{
                  '& .Mui-disabled': {
                    input: {
                      backgroundColor: '#DADADA',
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel className="text-content-14 !text-[#162A4C] " htmlFor="email">
                {t('email')}
              </InputLabel>
              <TextField
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                size="small"
                required
                disabled={isSuccess || isLoading}
                placeholder={t('email_placeholder') || ''}
                className="w-full"
                sx={{
                  '& .Mui-disabled': {
                    input: {
                      backgroundColor: '#DADADA',
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel className="text-content-14 !text-[#162A4C] " htmlFor="phone">
                {t('phone_number')}
              </InputLabel>
              <TextField
                name="phone"
                defaultValue={phone}
                disabled={!!token || isSuccess || isLoading}
                size="small"
                required
                placeholder={t('phone_placeholder') || ''}
                className="w-full"
                inputProps={{ maxLength: 12 }}
                onKeyUp={handleChangeValue}
                onKeyDown={enforceFormat}
                sx={{
                  '& .Mui-disabled': {
                    input: {
                      backgroundColor: '#DADADA',
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel className="text-content-14 !text-[#162A4C] " htmlFor="note">
                {t('note')}
              </InputLabel>
              <TextField
                multiline
                rows={4}
                name="description"
                size="small"
                className="w-full !rounded-lg"
                placeholder={t('note_placeholder') || ''}
                disabled={isSuccess || isLoading}
              />
            </Box>
          </Box>
          {!!phone && !validatePhoneNumber(phone) && (
            <Box className="text-brand-1 text-center mt-2">{t('wrong_format_phone_number')}</Box>
          )}
          {!!email && !ValidateEmail(email) && (
            <Box className="text-brand-1 text-center mt-2">{t('wrong_format_email')}</Box>
          )}
          <Box className="flex justify-end mt-4 w-full">
            <Button
              type="submit"
              className="!bg-brand-1 !text-white !normal-case !text-content-16 !rounded-lg !font-[500] w-[144px] disabled:!bg-grey-1"
              disabled={!!isDisabledButton || isLoading || isSuccess}
            >
              {t('send_request')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ReconnectModal
