import { AccountCircle, Logout } from '@mui/icons-material'
import { Divider } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import usePath from 'hooks/usePath'
import { ProfileResponse } from 'layouts/components/LoginModal/StepSignIn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Route, Switch, useHistory } from 'react-router-dom'
import { AuthApiService, GenericDataType } from 'services'
import Cookies from 'universal-cookie'
import { JWT_AUTHORIZATION } from 'utils/constants'
import { APP_TOKEN_LOCALKEY } from 'utils/constants/apiConstant'
import ROUTES from 'utils/constants/routesConstant'
import { generateMediaLink } from 'utils/functions'
import { FavoriteBorderRounded, FreeBreakfastOutlined, ReorderRounded } from '@mui/icons-material'
import ProfileDetail from './components/ProfileDetail'

const ProfilePage = () => {
  const { token } = useAuth()
  const { path } = usePath()
  const { t } = useTranslation('profile')
  const [selectedRoute, setSelectedRoute] = React.useState<string>('')
  const history = useHistory()
  const dispatch = useLoginModalDispatch()

  const { data } = AuthApiService.useGet<GenericDataType<ProfileResponse>>({
    url: '/me',
    options: { enabled: !!token },
  })

  const cookies = new Cookies()

  const listLink = [
    { label: t('profile'), route: '' },
    { label: t('address'), route: ROUTES.PROFILE_ADDRESS },
    { label: t('password'), route: ROUTES.PROFILE_RESET_PASSWORD },
  ]

  const listOuterLink = [
    { label: t('buy_history'), icon: <ReorderRounded className="!text-content-16" />, route: '' },
    {
      label: t('favorite'),
      icon: <FavoriteBorderRounded className="!text-content-16" />,
      route: `${ROUTES.FAVORITE_LIST}?commodity_type=HOUSE&page=1`,
    },
    { label: t('used_service'), icon: <FreeBreakfastOutlined className="!text-content-16" />, route: '' },
  ]

  const handleLogOut = () => {
    localStorage.removeItem(APP_TOKEN_LOCALKEY)
    cookies.remove(JWT_AUTHORIZATION)
    dispatch({
      type: ACTION_TYPE.INIT,
      payload: {
        name: '',
        avatar: '',
        phone: '',
        password: '',
        username: '',
        email: '',
      },
    })
    history.push('/')
  }

  return (
    <Box>
      <CustomBreadcrumbs />
      <Box className="flex flex-wrap max-w-[1440px] mx-auto px-14 py-14 gap-8 justify-between">
        <Box className="w-full tablet:w-[180px]">
          <Box className="flex flex-col justify-center items-center">
            <Avatar src={generateMediaLink(data?.data?.result[0]?.avatar)} className="!w-[50px] !h-[50px]" />
            <Box className="text-content-16 font-bold text-black-1 mt-3">{data?.data?.result[0]?.name}</Box>
          </Box>
          <Divider className="!my-4" />
          <Box className="flex flex-col gap-2">
            <Box className="flex gap-2 text-brand-1 text-content-14 font-medium items-center">
              <AccountCircle />
              <Box>{t('my_account')}</Box>
            </Box>
            {listLink.map((item, index) => (
              <Link
                key={index}
                to={`${path}${item.route}`}
                className={`ml-8 text-content-14 ${selectedRoute === item.route.replace('/', '') && 'text-brand-1'}`}
                onClick={() => setSelectedRoute(item.route.replace('/', ''))}
              >
                {item.label}
              </Link>
            ))}
            {listOuterLink.map((item, index) => (
              <Link key={index} to={item.route} className={`text-content-14 font-semibold flex gap-4`}>
                <Box>{item.icon}</Box>
                <Box>{item.label}</Box>
              </Link>
            ))}
          </Box>
          <Divider className="!my-4" />
          <Box className="flex gap-2 text-brand-1 text-content-14 font-medium items-center">
            <Box className="ml-8 text-center cursor-pointer" onClick={handleLogOut}>
              {t('log_out')} <Logout />
            </Box>
          </Box>
        </Box>
        <Box className="w-full tablet:w-[calc(100%_-_228px)]">
          <Switch>
            <Route exact path={path}>
              <ProfileDetail setPath={setSelectedRoute} />
            </Route>
            <Route exact path={`${path}/:profileId`}>
              <ProfileDetail setPath={setSelectedRoute} />
            </Route>
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfilePage
