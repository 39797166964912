import React from 'react'
import { useParams } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'
import Address from './Address'
import ChangePassword from './ChangePassword'
import UserDetail from './UserDetail'

const ProfileDetail = ({ setPath }: { setPath: (path: string) => void }) => {
  const { profileId } = useParams<{ profileId: string }>()

  React.useEffect(() => {
    if (profileId) {
      setPath(profileId)
    } else setPath('')
  }, [profileId, setPath])

  switch (profileId) {
    case ROUTES.PROFILE_ADDRESS.replace('/', ''):
      return <Address />
    case ROUTES.PROFILE_RESET_PASSWORD.replace('/', ''):
      return <ChangePassword />
    default:
      return <UserDetail />
  }
}

export default ProfileDetail
