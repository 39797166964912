import { Search } from '@mui/icons-material'
import { Autocomplete, Box, Button, TextField } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ListAutocomplete } from '.'

type CurrentParams = {
  [key: string]: string | undefined
}

const FilterForProject = ({
  route,
  listAutocomplete,
  isLoading,
  currentParams,
}: {
  currentParams: CurrentParams
  route?: string
  listAutocomplete?: ListAutocomplete[]
  isLoading?: boolean
}) => {
  const { t } = useTranslation('home')
  const history = useHistory()
  const [state, setState] = React.useState<CurrentParams>()

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    let newState = {}
    newState = {
      q: state?.q,
      project_name: state?.project_name,
      type: state?.type === 'SUPER_CAR' || state?.type === 'MOTOR_BIKE' ? undefined : state?.type,
    }

    history.push(`${route}?${qs.stringify(newState)}`)
  }

  React.useEffect(() => {
    setState(currentParams)
  }, [currentParams])

  return (
    <Box component={'form'} onSubmit={handleSubmit} className="flex flex-wrap gap-3 items-end">
      {listAutocomplete?.map((item, index) => (
        <Box key={index}>
          <Box className="text-black-1 text-content-14 font-bold mb-1">{item.label}</Box>
          <Autocomplete
            disablePortal
            className="w-[188px]"
            options={item.options}
            defaultValue={item.options.find(x => x.value === currentParams?.[item.name])}
            onChange={(e, v) => setState(prev => ({ ...prev, [item.name]: v?.value }))}
            sx={{
              '& .MuiInputBase-root': {
                padding: '5px 14px',
                borderRadius: '12px',
              },
              'input::placeholder': { fontStyle: 'italic', fontSize: '14px' },
            }}
            renderInput={params => <TextField {...params} name={item.name} placeholder={item.placeholder} />}
          />
        </Box>
      ))}
      <Button type="submit" className="h-12 !bg-brand-1 w-[90px] !rounded-xl">
        <Search className="text-white" />
      </Button>
    </Box>
  )
}

export default FilterForProject
