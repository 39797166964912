import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import OutlinedInput from '@mui/material/OutlinedInput'
import useAuth from 'hooks/useAuth'
import { ProfileResponse } from 'layouts/components/LoginModal/StepSignIn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthApiService, DefaultApiService, GenericDataType, UserApiService } from 'services'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import RadioControl from 'components/RadioControl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ValidateEmail, dataURItoBlob, generateMediaLink, getDateString } from 'utils/functions'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import { useSnackbar } from 'notistack'
import SnackbarAction from 'components/SnackbarAction'
import { UploadResponse } from 'layouts/components/LoginModal/StepUpdateProfile'
import { useMediaQuery } from '@mui/material'

const UserDetail = () => {
  const { token } = useAuth()
  const { t } = useTranslation('profile')

  const { data, refetch } = AuthApiService.useGet<GenericDataType<ProfileResponse>>({
    url: '/me',
    options: { enabled: !!token },
  })

  const { mutate, isSuccess } = UserApiService.usePatch<GenericDataType<ProfileResponse>>({
    url: `/${data?.data?.result[0]?._id}/profile`,
  })

  const { data: dataUpload, mutate: mutateUpload } = DefaultApiService.usePost<GenericDataType<UploadResponse>>({
    url: '/uploads',
  })

  const dispatch = useLoginModalDispatch()
  const [images, setImages] = React.useState<ImageListType>([])
  const [isChangeEmail, setIsChangeEmail] = React.useState<boolean>(false)
  const [dob, setDob] = React.useState<Date | null>()
  const [gender, setGender] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const matches = useMediaQuery('(min-width: 888px)')

  const onChange = (imageList: ImageListType) => {
    setImages(imageList)
  }

  const accountType = React.useMemo(() => {
    if (data?.data?.result[0]?.role_name === 'CUSTOMER') return t('customer_account')
    return t('agent_account')
  }, [data?.data?.result, t])

  const isNothingChanged = (formData: { [k: string]: FormDataEntryValue }) => {
    let flag = false
    if (
      email === data?.data?.result[0]?.email &&
      dob &&
      formData?.dob?.toString() === getDateString(data?.data?.result[0]?.dob) &&
      !images[0]?.data_url &&
      data?.data?.result[0]?.gender === formData?.gender
    ) {
      flag = true
    } else flag = false
    return flag
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    if (isNothingChanged(formProps)) {
      return
    }
    dispatch({
      type: ACTION_TYPE.INIT,
      payload: { username: formProps?.username?.toString() },
    })
    if (!ValidateEmail(email)) {
      enqueueSnackbar(t('email_invalid'), { action: SnackbarAction, variant: 'error' })
      return
    }
    const birthday = !!dob && getDateString(dob, 'yyyy-mm-dd')
    const img = dataUpload?.data?.result[0]?.images[0] ?? data?.data?.result[0]?.avatar
    mutate({
      data: {
        gender: formProps?.gender?.toString(),
        dob: birthday,
        email: email,
        avatar: img,
      },
    })
  }

  React.useEffect(() => {
    if (isSuccess) {
      refetch()
      enqueueSnackbar(t('update_success'), { variant: 'success' })
    }
  }, [enqueueSnackbar, isSuccess, refetch, t])

  React.useEffect(() => {
    const image = images[0]?.data_url
    if (image) {
      const formData = new FormData()
      formData.append('images', dataURItoBlob(images[0]?.data_url), images[0]?.file?.name)
      mutateUpload({
        data: formData,
      })
    }
  }, [images, mutateUpload])

  React.useEffect(() => {
    if (data) {
      if (data?.data?.result[0]?.dob) {
        setDob(new Date(data?.data?.result[0]?.dob))
      }
      if (data?.data?.result[0]?.gender) {
        setGender(data?.data?.result[0]?.gender)
      }
      if (data?.data?.result[0]?.email) {
        setEmail(data?.data?.result[0]?.email)
      }
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          avatar: generateMediaLink(data?.data?.result[0]?.avatar),
        },
      })
    }
  }, [data, dispatch])

  return (
    <Box className="bg-white p-10 rounded-xl">
      <Box className={`flex flex-wrap items-center ${matches ? 'justify-around' : 'justify-center gap-6'}`}>
        <Box className="flex flex-wrap flex-col gap-2" component={'form'} onSubmit={handleSubmit}>
          <Box className="flex flex-wrap gap-2">
            <Box className="font-bold text-content-18">{t('user_detail')}</Box>
            <Box className="text-content-14 bg-[#f4f4f4] px-2 py-1 rounded-xl">{accountType}</Box>
          </Box>
          <Box className="flex flex-wrap text-content-14 gap-5 min-h-[40px] items-center">
            <Box className="w-[96px]">{t('name')}</Box>
            <Box className="font-bold">{data?.data?.result[0]?.name}</Box>
          </Box>
          <Box className="flex flex-wrap text-content-14 gap-5 min-h-[40px] items-center">
            <Box className="w-[96px]">{t('email')}</Box>
            <Box className="flex items-center gap-3">
              {isChangeEmail ? (
                <OutlinedInput
                  className="w-full"
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '10px 16px',
                      fontSize: '14px',
                    },
                  }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder={t('email') || ''}
                  name={'email'}
                  required
                />
              ) : (
                <Box className="flex items-center gap-3">
                  {data?.data?.result[0]?.email || `(${t('empty')})`}
                  <Box
                    component={'a'}
                    onClick={() => setIsChangeEmail(true)}
                    className="text-brand-1 underline cursor-pointer hover:text-grey-1"
                  >
                    {t('change')}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="flex flex-wrap text-content-14 gap-5 min-h-[40px] items-center">
            <Box className="w-[96px]">{t('phone_number')}</Box>
            <Box>{data?.data?.result[0]?.phone}</Box>
          </Box>
          <Box className="flex flex-wrap text-content-14 gap-5 items-center min-h-[40px]">
            <Box className="w-[96px]">{t('gender')}</Box>
            <Box>
              <RadioGroup
                value={gender}
                name="gender"
                className="flex flex-wrap !flex-row"
                onChange={e => setGender(e.target.value)}
              >
                <FormControlLabel value="MALE" control={<RadioControl />} label={t('male')} />
                <FormControlLabel value="FEMALE" control={<RadioControl />} label={t('female')} />
              </RadioGroup>
            </Box>
          </Box>
          <Box className="flex flex-wrap text-content-14 gap-5 items-center min-h-[40px]">
            <Box className="w-[96px]">{t('birthday')}</Box>
            <Box>
              <DatePicker
                selected={dob}
                onChange={date => setDob(date)}
                name="dob"
                dateFormat="dd/MM/yyyy"
                className="border-[1px] rounded-lg p-2 text-right"
                popperPlacement="top-end"
              />
            </Box>
          </Box>
          <Button
            type="submit"
            className="!w-[144px] !normal-case !bg-brand-1 !text-white !text-content-14 !mx-auto !mt-6"
          >
            {t('save')}
          </Button>
        </Box>
        {matches ? <Divider orientation={'vertical'} flexItem /> : <></>}
        <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
          {({ imageList, onImageUpload }) => (
            <Box className="flex flex-col items-center justify-center gap-3">
              {imageList?.length ? (
                imageList.map((image, index) => (
                  <Box
                    component={'img'}
                    src={image['data_url']}
                    alt=""
                    width="110px"
                    height="110px"
                    className="object-contain rounded-full"
                    key={index}
                  />
                ))
              ) : (
                <Avatar className="!w-[110px] !h-[110px]" src={generateMediaLink(data?.data?.result[0]?.avatar)} />
              )}
              <Button
                onClick={() => {
                  setImages([])
                  onImageUpload()
                }}
                variant="outlined"
                className="!border-brand-1 !normal-case !text-content-12 !text-brand-1"
              >
                Chọn ảnh
              </Button>
            </Box>
          )}
        </ImageUploading>
      </Box>
    </Box>
  )
}

export default UserDetail
