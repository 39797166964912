import { Box, CircularProgress } from '@mui/material'
import flashSalePNG from 'assets/images/flash-sale.png'
import playIcon from 'assets/images/play.svg'
import CardItem from 'components/CardItem'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, DefaultApiService, GenericDataType, HouseFavoriteApiService } from 'services'
import { domain } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { generateHref } from 'utils/functions'
import { ItemType } from 'utils/types'

const ShortTimePlace = () => {
  const [isOpen, setOpen] = useState(false)

  const { data, isLoading, refetch } = HouseFavoriteApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 3,
      type_rental: 'DAY',
    } as ApiParamsProps,
  })

  const { data: dataVideo } = DefaultApiService.useGet<GenericDataType<ItemType>>({
    url: '/master-data/category/video',
    params: {
      commodity_type: 'HOUSE',
    },
  })

  const { t } = useTranslation('home')
  const history = useHistory()

  const filteredData = React.useMemo(() => {
    if (data?.data?.result && data?.data?.result?.length) {
      return data?.data?.result
    } else return []
  }, [data])
  const video = dataVideo?.data?.result?.[0]?.link

  const idVideo = React.useMemo(() => {
    if (video?.includes('youtube')) {
      return video.replace('https://www.youtube.com/watch?v=', '')
    }
  }, [video])

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        href={`${ROUTES.HOUSE_FAVORITE}?page=1&type_rental=DAY`}
        title={
          <>
            <Box>{t('short_time_place_title')}</Box>
            <Box className="text-grey-1">{t('short_time_place_detail', { counter: data?.data?.total })}</Box>
          </>
        }
        type={TitleWithRouteType.BACKGROUND_TITLE}
      />
      <div className="flex gap-5">
        {video && (
          <Box className="w-[516px] h-[437px] flex justify-center items-center bg-black rounded-xl">
            {isOpen ? (
              <>
                {video?.includes('youtube') ? (
                  <iframe
                    className="w-full h-full"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/${idVideo}?autoplay=0`}
                  ></iframe>
                ) : (
                  <video controls={true} width={'100%'} height={'100%'} className="w-full h-full">
                    <source src={domain + video} type="video/mp4" />
                    <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"></track>
                  </video>
                )}
              </>
            ) : (
              <div className="cursor-pointer w-full h-full relative" onClick={() => setOpen(true)}>
                <img src={flashSalePNG} alt="" className="h-full w-full" />
                <img
                  src={playIcon}
                  alt=""
                  className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            )}
          </Box>
        )}
        {isLoading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="flex flex-wrap gap-5">
            {filteredData.map((item, index) => (
              <CardItem
                id={item._id}
                key={index}
                type={item.type}
                name={item.name}
                image={item?.images?.[0]?.image || ''}
                bathCount={item.n_bathroom}
                bedCount={item.n_bedroom}
                price={item?.sale_price || item?.price}
                type_rental={item.type_rental}
                updatedTime={item.updated_at}
                isLiked={item.is_favorite}
                onRefetchAfterLike={() => refetch()}
                href={generateHref({ item: item })}
              />
            ))}
          </Box>
        )}
      </div>
      {!isLoading && !data?.data?.total && (
        <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>
      )}
    </Box>
  )
}

export default ShortTimePlace
