import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import NameBox from './NameBox'
import ListRoom from './ListRoom'
import ListProperties, { Properties } from './ListProperties'
import RoomCounter from './RoomCounter'
import RoomDescription from './RoomDescription'

type Props = {
  houseName?: string
  rating?: number
  address?: string
  longitude?: number
  latitude?: number
  roomDetail?: Properties
  properties?: Properties
  roomCounter?: Properties
  description?: string
}

const HouseDetail: React.FC<Props> = props => {
  const { t } = useTranslation('detail')
  return (
    <Box>
      <Box className="text-content-18 font-bold text-brand-1 mb-2">{t('bds_detail')}</Box>
      <Box className="p-4">
        <NameBox {...props} />
        <ListRoom {...props} />
        <ListProperties {...props} />
        <RoomCounter {...props} />
        <RoomDescription description={props?.description} />
      </Box>
    </Box>
  )
}

export default HouseDetail
