import React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { ModalAddressType } from './ModalAddress'
import { AddressProfile } from 'layouts/components/LoginModal/StepSignIn'
import { CheckCircleRounded } from '@mui/icons-material'

type Props = {
  onOpen?: (id?: string | number) => void
  onChangeType?: (type: ModalAddressType) => void
  data?: AddressProfile
  setDefault?: (id?: string | number) => void
}

const AddressItem: React.FC<Props> = ({ onOpen, onChangeType, data, setDefault }) => {
  const { t } = useTranslation('profile')

  const listKey = React.useMemo(() => {
    return [
      { label: t('name'), value: data?.name, isBold: true },
      { label: t('address_profile'), value: data?.address, isBold: false },
      { label: t('phone_number'), value: data?.phone, isBold: false },
    ]
  }, [data?.address, data?.name, data?.phone, t])

  return (
    <Box className="rounded-xl border-[1px] border-grey-1 px-4 py-2 flex flex-wrap justify-between my-4">
      <Box>
        {listKey.map((item, index) => (
          <Box key={index} className="flex flex-wrap gap-x-6 gap-y-4 text-content-14 my-2">
            <Box>{item.label}</Box>
            <Box className={`${item.isBold && 'font-bold'}`}>{item.value}</Box>
            {item.isBold && data?.is_default && (
              <Box className="flex items-center  text-brand-1 gap-2 font-semibold">
                <CheckCircleRounded className="!text-[16px]" />
                <Box className="text-content-12">{t('default_address')}</Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box className="flex flex-col justify-between">
        <Box className="flex gap-3 text-content-14 font-semibold justify-end">
          <Box
            className="text-black-1 cursor-pointer hover:text-grey-1"
            onClick={() => {
              onChangeType?.(ModalAddressType.EDIT)
              onOpen?.(data?.id || 0)
            }}
          >
            {t('edit')}
          </Box>
          {!data?.is_default && (
            <>
              <Divider orientation="vertical" flexItem />
              <Box
                className="text-brand-1 cursor-pointer hover:text-grey-1"
                onClick={() => {
                  onChangeType?.(ModalAddressType.DELETE)
                  onOpen?.(data?.id || 0)
                }}
              >
                {t('delete')}
              </Box>
            </>
          )}
        </Box>
        {!data?.is_default && (
          <Button
            variant="outlined"
            className="!text-brand-1 !normal-case !border-brand-1 !font-bold"
            onClick={() => setDefault?.(data?.id || 0)}
          >
            {t('set_default_address')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default AddressItem
