export const APP_TOKEN_LOCALKEY = 'APP_TOKEN'
export const AUTH_API_ENDPOINT = process.env.REACT_APP_API_AUTH_ENDPOINT
export const COMODITY_IMAGE_API_ENDPOINT = process.env.REACT_APP_API_COMODITY_IMAGE_ENDPOINT
export const DUMMY_API_ENDPOINT = process.env.REACT_APP_API_DUMMY_ENDPOINT
export const FAVORITE_API_ENDPOINT = process.env.REACT_APP_API_FAVORITE_ENDPOINT
export const FLASH_SALE_API_ENDPOINT = process.env.REACT_APP_API_FLASH_SALE_ENDPOINT
export const FLASH_SALE_COMING_API_ENDPOINT = process.env.REACT_APP_API_FLASH_SALE_COMING_ENDPOINT
export const HOUSE_API_ENDPOINT = process.env.REACT_APP_API_HOUSE_ENDPOINT
export const HOUSE_HOT_API_ENDPOINT = process.env.REACT_APP_API_HOUSE_HOT_ENDPOINT
export const HOUSE_FAVORITE_API_ENDPOINT = process.env.REACT_APP_API_HOUSE_FAVORITE_ENDPOINT
export const REVIEW_API_ENDPOINT = process.env.REACT_APP_API_REVIEW_ENDPOINT
export const USER_API_ENDPOINT = process.env.REACT_APP_API_USER_ENDPOINT
export const VEHICLE_API_ENDPOINT = process.env.REACT_APP_API_VEHICLE_ENDPOINT
export const VEHICLE_SEARCH_API_ENDPOINT = process.env.REACT_APP_API_VEHICLE_SEARCH_ENDPOINT
export const NEWS_API_ENDPOINT = process.env.REACT_APP_API_NEWS_ENDPOINT
export const OTP_ENDPOINT = process.env.REACT_APP_API_OTP_ENDPOINT
export const ROLES_ENDPOINT = process.env.REACT_APP_API_ROLES_ENDPOINT
export const DEFAULT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const ORDER_ENDPOINT = process.env.REACT_APP_ORDER_API_ENDPOINT
export const COMMENT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '/comments'
